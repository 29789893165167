import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import {
  ICountry,
  IPostalCodeDetailsResponse,
  IStateList
} from '../../../../../interfaces/b2bPlacesDetails';

export const getCountriesList = async ({
  setLoading,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<ICountry[] | []>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/businesses/places/countries`);
    setData(response?.data?.data?.countries);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching Country Details', error);
  } finally {
    setLoading(false);
  }
};

export const getStatesList = async ({
  setLoading,
  setData,
  countyCode
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IStateList[] | []>>;
  countyCode: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/businesses/places/countries/${countyCode}/states`);
    setData(response?.data?.data?.states);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching State Details', error);
  } finally {
    setLoading(false);
  }
};

export const getPostalCodeDetails = async ({
  setLoading,
  countryCode,
  postalCode,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  countryCode: string;
  postalCode: string;
  setData: Dispatch<SetStateAction<IPostalCodeDetailsResponse | undefined>>;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(
      `/businesses/places/countries/${countryCode}/postalcodes/${postalCode}`
    );
    setData(response?.data?.data?.postalCodeDetails);
  } catch (error: any) {
    setData(undefined);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching State Details', error);
  } finally {
    setLoading(false);
  }
};
