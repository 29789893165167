import { Button, Empty, Form, Input, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { getBusinessList } from '../../api';
import { IB2BBulkOrder } from '../../../../../interfaces/b2bBulkOrder';

interface IBusinessDetails {
  disabledStep: boolean;
  nextStep: () => void;
  setBusinessId: Dispatch<SetStateAction<string>>;
  compKey: string | number;
}
const BusinessDetails: FC<IBusinessDetails> = ({
  compKey,
  nextStep,
  disabledStep,
  setBusinessId
}) => {
  const defaultSearchType = 'brandName';
  const [searchBusinessForm] = Form.useForm();
  const [businessListForm] = Form.useForm();

  const clearListForm = () => {
    businessListForm.resetFields();
  };

  const initialState = {
    selectedSearchType: defaultSearchType,
    enteredValue: '',
    searchLoading: false,
    businessList: [] as IB2BBulkOrder[]
  };

  const [selectedSearchType, setSelectedSearchType] = useState(initialState.selectedSearchType);
  const [enteredValue, setEnteredValue] = useState(initialState.enteredValue);
  const [searchLoading, setSearchLoading] = useState(initialState.searchLoading);
  const [businessList, setBusinessList] = useState(initialState.businessList);

  const resetStates = () => {
    clearListForm();
    setSelectedSearchType(initialState.selectedSearchType);
    setEnteredValue(initialState.enteredValue);
    setSearchLoading(initialState.searchLoading);
    setBusinessList(initialState.businessList);
  };

  useEffect(() => {
    if (compKey) {
      resetStates();
    }
  }, [compKey]);

  const onChangeSearchType = (e: RadioChangeEvent) => {
    setSelectedSearchType(e.target.value);
    searchBusinessForm.resetFields(['searchInput']);
    clearListForm();
    setEnteredValue('');
  };

  const onSearchBusiness = async (value: string) => {
    clearListForm();
    if (enteredValue || value?.trim()) {
      setEnteredValue(value?.trim());
      await getBusinessList({
        setLoading: setSearchLoading,
        searchKey: selectedSearchType,
        searchValue: value?.trim(),
        setData: setBusinessList
      });
    }
  };

  const onSearchBusinessChange = () => {
    setEnteredValue(initialState.enteredValue);
    businessListForm.resetFields();
  };
  const onClickProceed = ({ selectedBusiness }: { selectedBusiness: string }) => {
    if (selectedBusiness) {
      setBusinessId(selectedBusiness);
      nextStep();
    } else {
      setBusinessId('');
    }
  };

  return (
    <Spin spinning={searchLoading}>
      <div
        className={`${
          disabledStep ? 'opacity-50 bg-gray-200 cursor-not-allowed select-none' : ''
        } px-3 py-4`}>
        <div className="flex gap-2 flex-wrap items-center mb-3">
          <Formlabel labelText="Search Business by :" required />
          <Radio.Group
            disabled={disabledStep}
            defaultValue={defaultSearchType}
            onChange={onChangeSearchType}>
            <Radio value="brandName">Brand Name</Radio>
            <Radio value="gstin">GSTIN</Radio>
          </Radio.Group>
        </div>

        <Form
          disabled={disabledStep}
          layout="vertical"
          id="searchBusinessForm"
          form={searchBusinessForm}
          scrollToFirstError>
          <div className="flex flex-col gap-2">
            <Formlabel
              required
              labelText={`Enter ${selectedSearchType === 'brandName' ? 'Brand Name' : 'GSTIN'}`}
            />
            <Form.Item
              name="searchInput"
              rules={[
                {
                  required: true,
                  message: `Please enter ${
                    selectedSearchType === 'brandName' ? 'brand name' : 'GSTIN'
                  } to proceed further`
                }
              ]}>
              <Input.Search
                allowClear
                placeholder="Input search text"
                onChange={onSearchBusinessChange}
                onSearch={onSearchBusiness}
                enterButton
              />
            </Form.Item>
          </div>
        </Form>

        {!searchLoading &&
          enteredValue &&
          (businessList?.length ? (
            <Form
              disabled={!enteredValue || disabledStep}
              layout="vertical"
              id="businessListForm"
              form={businessListForm}
              onFinish={onClickProceed}>
              <div className="flex flex-col gap-2">
                <Formlabel required labelText="Select Business" />
                <Form.Item
                  name="selectedBusiness"
                  rules={[
                    {
                      required: true,
                      message: `Please select any business`
                    }
                  ]}>
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    placeholder="Select business"
                    options={businessList?.map((item) => ({
                      label: `${item?.id} - ${item?.brandName}`,
                      value: item?.id
                    }))}
                  />
                </Form.Item>
              </div>

              <div className="flex justify-center">
                <Button type="primary" htmlType="submit">
                  Proceed
                </Button>
              </div>
            </Form>
          ) : (
            <Empty
              className="py-4"
              description={
                <p>
                  No business found for <span className="font-semibold">{enteredValue}</span>.
                </p>
              }
            />
          ))}
      </div>
    </Spin>
  );
};

export default BusinessDetails;
