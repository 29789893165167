import React from 'react';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface IFixedCharges {
  _id: string;
  userId: string;
  amount: number;
  tax?: number;
  totalAmount?: number;
  noticeType: string;
  serviceType?: string;
  provider: string;
  pageies: number;
  createdAt: string;
  updatedAt: string;

}

const FixedChargesTable = () => {
  const tableColumns: ColumnsType<IFixedCharges> = [
    {
        title: 'Notice Type',
        dataIndex: 'noticeType',
        key: 'noticeType',
        render: (noticeType) =><div style={{fontWeight:500}}>{noticeType}</div>,
    },
    {
        title: 'Number of pages',
        dataIndex: 'pageies',
        key: 'pageies',
        align: 'center',
        render: (pageies) => pageies ?? 'N/A',
    },
    {
      title: 'Charges (₹)',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (amount) => `₹ ${amount}` ?? 'N/A',
    },
    {
      title: 'Tax (18%)',
      dataIndex: 'tax',
      key: 'tax',
      align: 'center',
      render: (tax) => `₹ ${tax}` ?? 'N/A',
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      align: 'center',
      render: (totalAmount) => `₹ ${totalAmount}` ?? 'N/A',
      width: 500,
    },
  
    // {
    //   title: 'Service Type',
    //   dataIndex: 'serviceType',
    //   key: 'serviceType',
    //   render: (serviceType) => serviceType ?? 'N/A',
    // },
    // {
    //   title: 'Provider',
    //   dataIndex: 'provider',
    //   key: 'provider',
    //   render: (provider) => provider ?? 'N/A',
    // },
   
  ];

  return {tableColumns} 
};

export default FixedChargesTable;
