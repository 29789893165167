import { ColumnsType } from 'antd/es/table';
import React, { Dispatch, SetStateAction } from 'react';
import { checkValidation, validateColumnKeys } from './BulkOrderUtils';

interface BulkOrderColumnsProps {
  bookedAllItems: boolean;
}

export const getError = (order: { [key: string]: any }, value: any, msg: string) => {
  return (
    <p
      className={`${
        checkValidation(order)?.invalidOrderKeys?.includes(msg) ? 'text-red-600' : ''
      } `}>
      {value}
    </p>
  );
};

export const BulkOrderColumns = ({ bookedAllItems = false }: BulkOrderColumnsProps) => {
  let tableColumns: ColumnsType<{ [key: string]: any }> = [
    {
      title: 'Order Id',
      dataIndex: 'order_id',
      fixed: 'left',
      width: 150,
      render: (value, order) => getError(order, value, validateColumnKeys?.order_id)
    },
    {
      title: (
        <div>
          Delivery Address <br /> Line 1
        </div>
      ),
      dataIndex: 'delivery_address_line1',
      width: 200,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.delivery_address_line1)
    },
    {
      title: (
        <div>
          Delivery Address <br /> Line 2
        </div>
      ),
      dataIndex: 'delivery_address_line2',
      width: 200,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.delivery_address_line2)
    },
    {
      title: (
        <div>
          Delivery <br />
          Email
        </div>
      ),
      dataIndex: 'delivery_email',
      width: 150,
      render: (email, order) => getError(order, email, validateColumnKeys?.delivery_email)
    },
    {
      title: 'Delivery Customer Name',
      dataIndex: 'delivery_customer_name',
      width: 150,
      render: (name, order) => getError(order, name, validateColumnKeys?.delivery_customer_name)
    },
    {
      title: (
        <div>
          Delivery Address
          <br /> Country
        </div>
      ),
      dataIndex: 'delivery_address_country',
      width: 150,
      render: (data, order) => getError(order, data, validateColumnKeys?.delivery_address_country)
    },
    {
      title: (
        <div>
          Delivery Address <br /> State
        </div>
      ),
      dataIndex: 'delivery_address_state',
      width: 150,
      render: (data, order) => getError(order, data, validateColumnKeys?.delivery_address_state)
    },
    {
      title: (
        <div>
          Delivery Address <br /> City
        </div>
      ),
      dataIndex: 'delivery_address_city',
      width: 150,
      render: (data, order) => getError(order, data, validateColumnKeys?.delivery_address_city)
    },
    {
      title: 'Delivery Address Pincode',
      dataIndex: 'delivery_address_pincode',
      width: 150,
      render: (pincode, order) =>
        getError(order, pincode, validateColumnKeys?.delivery_address_pincode)
    },
    {
      title: (
        <div>
          Delivery Phone <br /> Number
        </div>
      ),
      dataIndex: 'delivery_phone_number',
      width: 200,
      render: (mobileNo, order) =>
        getError(order, mobileNo, validateColumnKeys?.delivery_phone_number)
    },
    {
      title: (
        <div>
          Billing Customer
          <br />
          Name
        </div>
      ),
      dataIndex: 'billing_customer_name',
      width: 150,
      render: (name, order) => getError(order, name, validateColumnKeys?.billing_customer_name)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> Line 1
        </div>
      ),
      dataIndex: 'billing_address_line1',
      width: 150,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.billing_address_line1)
    },
    {
      title: (
        <div>
          Billing Address <br />
          Line 2
        </div>
      ),
      dataIndex: 'billing_address_line2',
      width: 150,
      render: (address, order) =>
        getError(order, address, validateColumnKeys?.billing_address_line2)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> City
        </div>
      ),
      dataIndex: 'billing_address_city',
      width: 150,
      render: (data, order) => getError(order, data, validateColumnKeys?.billing_address_city)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> State
        </div>
      ),
      dataIndex: 'billing_address_state',
      width: 150,
      render: (data, order) => getError(order, data, validateColumnKeys?.billing_address_state)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> Country
        </div>
      ),
      dataIndex: 'billing_address_country',
      width: 150,
      render: (data, order) => getError(order, data, validateColumnKeys?.billing_address_country)
    },
    {
      title: (
        <div>
          Billing Address
          <br /> Pincode
        </div>
      ),
      dataIndex: 'billing_address_pincode',
      width: 150,
      render: (pincode, order) =>
        getError(order, pincode, validateColumnKeys?.billing_address_pincode)
    },
    {
      title: (
        <div>
          Billing Phone
          <br /> Number
        </div>
      ),
      dataIndex: 'billing_phone_number',
      width: 150,
      render: (phone, order) => getError(order, phone, validateColumnKeys?.billing_phone_number)
    },
    {
      title: <div>Billing Email</div>,
      dataIndex: 'billing_email',
      width: 150,
      render: (email, order) => getError(order, email, validateColumnKeys?.billing_email)
    },
    {
      title: 'Shipment Length',
      dataIndex: 'shipment_length',
      width: 150,
      render: (length, order) => getError(order, length, validateColumnKeys?.shipment_length)
    },
    {
      title: 'Shipment Width',
      dataIndex: 'shipment_width',
      width: 150,
      render: (width, order) => getError(order, width, validateColumnKeys?.shipment_width)
    },
    {
      title: 'Shipment Height',
      dataIndex: 'shipment_height',
      width: 150,
      render: (height, order) => getError(order, height, validateColumnKeys?.shipment_height)
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      width: 150,
      render: (weight, order) => getError(order, weight, validateColumnKeys?.weight)
    },
    {
      title: 'Item Description',
      dataIndex: 'item_description',
      width: 200,
      render: (description, order) => getError(order, description, validateColumnKeys?.weight)
    },
    {
      title: 'Estimated Value',
      dataIndex: 'estimated_value',
      width: 150,
      render: (value, order) => getError(order, value, validateColumnKeys?.estimated_value)
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      width: 100,
      render: (value, order) => getError(order, value, validateColumnKeys?.service_type)
    },
    {
      title: 'Delivery Vendor',
      dataIndex: 'delivery_vendor',
      width: 100,
      render: (value, order) => getError(order, value, validateColumnKeys?.delivery_vendor)
    },
    {
      title: 'Eway Bill',
      dataIndex: 'eway_bill',
      width: 150,
      render: (ewayBill) => ewayBill ?? 'NA'
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      width: 150,
      render: (expiryDate) => expiryDate ?? 'NA'
    },
    {
      title: 'Seller Gst No',
      dataIndex: 'seller_gst_no',
      width: 150,
      render: (sellerGstNo) => sellerGstNo ?? 'NA'
    },
    {
      title: 'Buyer Gst No',
      dataIndex: 'buyer_gst_no',
      width: 150,
      render: (buyerGstNo) => buyerGstNo ?? 'NA'
    },
    {
      title: 'Product Invoice No',
      dataIndex: 'product_invoice_no',
      width: 150,
      render: (invoiceNum) => invoiceNum ?? 'NA'
    },
    {
      title: 'Product Invoice Date',
      dataIndex: 'product_invoice_date',
      width: 150,
      render: (invoiceDate) => invoiceDate ?? 'NA'
    },
    {
      title: 'Taxable Value',
      dataIndex: 'taxable_value',
      width: 150,
      render: (taxableValue) => taxableValue ?? 'NA'
    },
    {
      title: 'CGST Amount',
      dataIndex: 'cgst_amount',
      width: 150,
      render: (cgstAmount) => cgstAmount ?? 'NA'
    },
    {
      title: 'SGST Amount',
      dataIndex: 'sgst_amount',
      width: 150,
      render: (sgstAmount) => sgstAmount ?? 'NA'
    },
    {
      title: 'IGST Amount',
      dataIndex: 'igst_amount',
      width: 150,
      render: (igstAmount) => igstAmount ?? 'NA'
    },

    {
      title: 'Item Category',
      dataIndex: 'item_category',
      width: 200,
      render: (value, order) => getError(order, value, validateColumnKeys?.item_category)
    }
  ];

  if (bookedAllItems) {
    tableColumns = [
      ...tableColumns,
      {
        title: 'Status',
        dataIndex: 'success',
        fixed: 'right',
        width: 100,
        render: (status) => (
          <div className={status ? 'text-green-600' : 'text-red-600'}>
            {status ? 'Success' : 'Failed'}
          </div>
        )
      },
      {
        title: 'AWB Id',
        dataIndex: 'awb',
        fixed: 'right',
        width: 150,
        render: (id) => (id ? id : 'NA')
      }
    ];
  }
  return tableColumns;
};
