import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Button, Modal, Spin, Tag } from 'antd';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import moment from 'moment';
import { AlertFilled } from '@ant-design/icons';
import { IAddressList } from '../../../../../interfaces/b2bAddressBook';
import { deleteAddressAPI } from '../api';

interface IDeleteAddress {
  deleteAddress: IAddressList | undefined;
  setDeleteAddress: Dispatch<SetStateAction<IAddressList | undefined>>;
  deleteAddressModal: boolean;
  setDeleteAddressModal: Dispatch<SetStateAction<boolean>>;
  handleGetAllAddress: () => void;
}
const DeleteAddress: FC<IDeleteAddress> = ({
  deleteAddress,
  setDeleteAddress,
  deleteAddressModal,
  setDeleteAddressModal,
  handleGetAllAddress
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkOnClose = () => {
    if (!isLoading) {
      onClickClose();
    }
  };

  const onClickClose = (fetchAll = false) => {
    setDeleteAddressModal(false);
    setTimeout(() => {
      setDeleteAddress(undefined);
    }, 400);

    if (fetchAll) {
      handleGetAllAddress();
    }
  };

  const onClickDelete = async () => {
    await deleteAddressAPI({
      setLoading: setIsLoading,
      businessId: deleteAddress?.businessId || '',
      addressId: deleteAddress?.id || '',
      callBackFun: () => onClickClose(true)
    });
  };

  return (
    <Modal
      onCancel={checkOnClose}
      footer={false}
      title={
        <div className="flex gap-2 items-center">
          <AlertFilled className="text-red-500" />
          <p className="m-0">Confirm Delete</p>
        </div>
      }
      open={deleteAddressModal}>
      <Spin spinning={isLoading}>
        <div
          className={`relative ${deleteAddress?.default && 'bg-yellow-50'} ${
            !deleteAddress?.default && 'hover:bg-gray-200'
          } p-3 w-full border border-gray-400 rounded-md transition duration-[400ms]`}>
          {deleteAddress?.default && (
            <Tag
              color="blue"
              className="m-0 text-md w-[100px] h-7 text-center absolute top-0 right-0 flex justify-center items-center">
              Default
            </Tag>
          )}

          <div className="flex flex-col justify-between h-full">
            <div>
              <p className="m-0 p-0 text-xs mb-1">
                Created: {moment(deleteAddress?.createdAt).format('DD-MM-YYYY, HH:MM:SS')}
              </p>

              <Tag className="w-full text-center text-lg bg-gray-300">{deleteAddress?.tag}</Tag>

              <h3 className="text-xl capitalize my-2">{deleteAddress?.name || '--'}</h3>
              {deleteAddress?.companyName && (
                <p>
                  <b>Company Name :- </b>
                  {deleteAddress?.companyName}
                </p>
              )}

              {deleteAddress?.savedAs && (
                <p>
                  <b>Saved As: </b>
                  {deleteAddress?.savedAs}
                </p>
              )}

              <div className="my-2">
                <p className="font-bold mt-2">Address :-</p>

                {deleteAddress?.line1 && (
                  <p>
                    <b>Line 1: </b>
                    {deleteAddress?.line1?.trim()}
                  </p>
                )}
                {deleteAddress?.line2 && (
                  <p>
                    <b>Line 2: </b>
                    {deleteAddress?.line2?.trim()}
                  </p>
                )}
                {deleteAddress?.line3 && (
                  <p>
                    <b>Line 3: </b>
                    {deleteAddress?.line3}
                  </p>
                )}

                {deleteAddress?.isdCode && (
                  <p>
                    <b>ISD Code: </b>
                    {deleteAddress?.isdCode}
                  </p>
                )}
              </div>

              <div className="mt-2">
                <p>
                  {deleteAddress?.city && (
                    <span className="mr-1">{`${deleteAddress?.city?.trim()},`}</span>
                  )}

                  {deleteAddress?.state && (
                    <span className="mr-1">
                      {deleteAddress?.state?.trim()} ({deleteAddress?.stateCode?.trim()}),
                    </span>
                  )}

                  {deleteAddress?.country && (
                    <span className="mr-1">{`${deleteAddress?.country?.trim()} (${deleteAddress?.countryCode?.trim()})`}</span>
                  )}

                  {deleteAddress?.postalCode && (
                    <span>{`- ${deleteAddress?.postalCode?.trim()}.`}</span>
                  )}
                </p>

                <div>
                  {deleteAddress?.mobileNo && (
                    <div className="flex gap-2 items-center">
                      <FaPhoneAlt className="text-blue-500" />
                      <div className="flex">
                        <p>{deleteAddress?.mobileNo}</p>
                        {deleteAddress?.alternatePhoneNo && (
                          <p>, {deleteAddress?.alternatePhoneNo}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {deleteAddress?.email && (
                    <div className="flex gap-2 items-center">
                      <MdEmail className="text-blue-500" />
                      <p>{deleteAddress?.email}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3 mt-5">
          <Button onClick={checkOnClose}>Cancel</Button>
          <Button
            onClick={onClickDelete}
            className="bg-red-500 !text-white 
          !border-none">
            Delete
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default DeleteAddress;
