import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../utils/apiRequest';
import { ErrorMessage, SuccessMessage } from '../../../../utils/ResponseMessages';
import { IB2BOrderDetails, IB2bV3OrdersList } from '../../../../interfaces/b2bV3OrdersList';
import {
  IB2BBulkOrder,
  IBulkOrdersListResponse,
  IBulkUploadUrl,
  ICheckAvailabilityResponse,
  IPickupAddressDetails,
  IValidatedResponse
} from '../../../../interfaces/b2bBulkOrder';

export const getB2BOrders = async ({
  setLoading,
  setData,
  pageLimit,
  skip,
  orderId,
  trackingId,
  clientReferenceId,
  businessOrderId,
  from,
  to,
  orderStatus,
  selectedProvider
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IB2bV3OrdersList | undefined>>;
  pageLimit: number;
  skip: number;
  orderId?: string;
  trackingId?: string;
  clientReferenceId?: string;
  businessOrderId?: string;
  from?: string;
  to?: string;
  orderStatus?: string;
  selectedProvider?: string;
}) => {
  setLoading(true);
  const offSet = (skip - 1) * pageLimit;
  const queryParams = `?offset=${offSet}&limit=${pageLimit}`;
  const orderIdQuery = orderId ? `&orderId=${orderId}` : '';
  const trackingIdQuery = trackingId ? `&trackingNumber=${trackingId}` : '';
  const clientReferenceIdQuery = clientReferenceId
    ? `&clientOrderReferenceId=${clientReferenceId}`
    : '';
  const businessOrderIdQuery = businessOrderId ? `&businessId=${businessOrderId}` : '';
  const dateRangeQuery = from ? `&from=${from}&to=${to}` : '';
  const orderStatusQuery = orderStatus ? `&status=${orderStatus}` : '';
  const deliverVendorQuery = selectedProvider ? `&carrierCode=${selectedProvider}` : '';

  try {
    const response = await httpClient.get(
      `/business-orders${queryParams}${orderIdQuery}${trackingIdQuery}${clientReferenceIdQuery}${businessOrderIdQuery}${dateRangeQuery}${orderStatusQuery}${deliverVendorQuery}`
    );
    setData(response?.data?.data);
  } catch (error: any) {
    setData({ count: 0, rows: [] });
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B orders:', error);
  } finally {
    setLoading(false);
  }
};

export const getB2BOrderDetails = async ({
  setLoading,
  setData,
  id
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IB2BOrderDetails | undefined>>;
  id: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/business-orders/${id}`);
    setData(response?.data?.data?.order);
  } catch (error: any) {
    setData(undefined);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B order details:', error);
  } finally {
    setLoading(false);
  }
};

export const getBusinessList = async ({
  setLoading,
  searchKey,
  searchValue,
  setData
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  searchKey: string;
  searchValue: string;
  setData: Dispatch<SetStateAction<IB2BBulkOrder[]>>;
}) => {
  setLoading(true);
  const searchQuery = searchValue ? `&${searchKey}=${searchValue}` : '';

  try {
    const response = await httpClient.get(`/businesses?offset=0&limit=20${searchQuery}`);
    setData(response?.data?.data);
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    setData([]);
    console.error('Error fetching business list:', error);
  } finally {
    setLoading(false);
  }
};

export const getBusinessAddresses = async ({
  setLoading,
  setData,
  businessId,
  postalCode,
  pageLimit,
  currentPage
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IPickupAddressDetails | null>>;
  businessId: string;
  postalCode?: string;
  pageLimit: number;
  currentPage: number;
}) => {
  setLoading(true);
  const offSet = pageLimit * (currentPage - 1);
  const queryParams = `?offset=${offSet}&limit=${pageLimit}`;
  const pincode = postalCode ? `&postalCode=${postalCode}` : '';
  try {
    const response = await httpClient.get(
      `/businesses/${businessId}/addresses${queryParams}${pincode}`
    );
    setData(response?.data?.data);
  } catch (error: any) {
    setData(null);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching business addresses:', error);
  } finally {
    setLoading(false);
  }
};

export const getUploadUrl = async ({
  setLoading,
  fileFormat,
  setData,
  callbackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  fileFormat: string;
  setData: Dispatch<SetStateAction<IBulkUploadUrl | null>>;
  callbackFunc?: () => void;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/business-bulk/uploadurl?fileFormat=${fileFormat}`);
    setData(response?.data?.data);
    if (callbackFunc) callbackFunc();
    return response?.data?.data;
  } catch (error: any) {
    setData(null);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching upload URL:', error);
    return false;
  } finally {
    setLoading(false);
  }
};

export const validateData = async ({
  setLoading,
  fileFormat,
  carrierPriority,
  addressId,
  businessId,
  bulkOrderId,
  rateCategory,
  setData,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  fileFormat: string;
  carrierPriority: string;
  addressId: string;
  businessId: string;
  bulkOrderId: string;
  rateCategory: string;
  callbackFun?: () => void;
  setData: Dispatch<SetStateAction<IValidatedResponse | null>>;
}) => {
  setLoading(true);
  const query = `?fileFormat=${fileFormat}&carrierPriority=${carrierPriority}&addressId=${addressId}&businessId=${businessId}&rateCategory=${rateCategory}`;

  try {
    const response = await httpClient.get(`/business-bulk/${bulkOrderId}/validate${query}`);
    setData(response?.data?.data);
    if (response?.data?.data?.noValidationErrors) {
      SuccessMessage(response?.data?.message);
      if (callbackFun) callbackFun();
    } else {
      ErrorMessage(response?.data?.message);
    }
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching upload URL:', error);
  } finally {
    setLoading(false);
  }
};

export const uploadBulkFile = async ({
  fileUrl,
  payload,
  setLoading,
  fileType,
  setSuccess
}: {
  fileUrl: string;
  payload: string | ArrayBuffer;
  setLoading: Dispatch<SetStateAction<boolean>>;
  fileType: string; // Assuming fileType is either 'xlsx' or 'csv'
  setSuccess: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);
  setSuccess(false);
  let contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'; // Default to xlsx
  if (fileType === 'csv') {
    contentType = 'text/csv';
  }
  const config = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType
    },
    body: payload
  };

  try {
    const response = await fetch(fileUrl, config);

    if (response.ok) {
      SuccessMessage('Uploaded SuccessFully');
      setSuccess(true);
    }
  } catch (error: any) {
    setSuccess(false);
    ErrorMessage(error?.response?.data?.message || 'Upload failed.');
    console.log('Upload error', error);
  } finally {
    setLoading(false);
  }
};

export const checkServiceability = async ({
  setLoading,
  setData,
  bulkOrderId
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<ICheckAvailabilityResponse | null>>;
  bulkOrderId: string;
}) => {
  setLoading(true);
  try {
    const response = await httpClient.get(`/business-bulk/${bulkOrderId}/serviceability`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData(null);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error checking serviceability:', error);
  } finally {
    setLoading(false);
  }
};

export const bookBulkOrder = async ({
  setLoading,
  bulkOrderId,
  callbackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  bulkOrderId: string;
  callbackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post(`/business-bulk/${bulkOrderId}/book`);
    if (callbackFun) callbackFun();
  } catch (error: any) {
    ErrorMessage(
      error?.response?.data?.message || 'An error occurred while booking the bulk order.'
    );
    console.error('Error posting business bulk:', error);
  } finally {
    setLoading(false);
  }
};

export const getB2BBulkOrdersList = async ({
  setLoading,
  setData,
  pageLimit,
  skip
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IBulkOrdersListResponse>>;
  pageLimit: number;
  skip: number;
}) => {
  setLoading(true);
  const offSet = (skip - 1) * pageLimit;
  const queryParams = `?offset=${offSet}&limit=${pageLimit}`;

  try {
    const response = await httpClient.get(`/business-bulk${queryParams}`);
    setData(response?.data?.data);
  } catch (error: any) {
    setData({ count: 0, bulkOrders: [] });
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B orders:', error);
  } finally {
    setLoading(false);
  }
};
