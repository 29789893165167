import { IProviderPreference } from '../../../../interfaces/b2bBulkOrder';

export const SAMPLE_BULK_FILE =
  'https://business-files.coreyo.com/samples/b2b_ccd_sample_bulk_data_sheet.xlsx';

export const PRIORITY_OPTIONS = [
  {
    label: 'CHEAPEST',
    value: 'CHEAPEST'
  },
  {
    label: 'FASTEST',
    value: 'FASTEST'
  },
  {
    label: 'DATA SHEET',
    value: 'DATA_SHEET'
  }
];

export const RATE_CATEGORY = [
  {
    label: 'STANDARDIZED',
    value: 'STANDARDIZED'
  },
  {
    label: 'CUSTOMIZED',
    value: 'CUSTOMIZED'
  }
];

export const PROVIDERS_DATA: IProviderPreference[] = [
  {
    name: 'Amazon',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Amazon.png',
    carrierCode: 'AMZN',
    pickupCutoffTime: '12:30 PM',
    services: [
      {
        name: 'Standard',
        serviceCode: 'STD',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'Blue Dart',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Bluedart.png',
    carrierCode: 'BLDT',
    pickupCutoffTime: '12:00 PM',
    services: [
      {
        name: 'Surface',
        serviceCode: 'SFC',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'Blue Dart',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Bluedart.png',
    carrierCode: 'BLDT',
    pickupCutoffTime: '12:00 PM',
    services: [
      {
        name: 'Standard Air',
        serviceCode: 'STD',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'CriticaLog',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_CriticaLog.png',
    carrierCode: 'CTCL',
    pickupCutoffTime: '02:00 PM',
    services: [
      {
        name: 'Fast Track',
        serviceCode: 'FST',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'CriticaLog',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_CriticaLog.png',
    carrierCode: 'CTCL',
    pickupCutoffTime: '02:00 PM',
    services: [
      {
        name: 'Super Critical',
        serviceCode: 'SCT',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'Delhivery',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__Delhivery.png',
    carrierCode: 'DLVR',
    pickupCutoffTime: '12:00 PM',
    services: [
      {
        name: 'Standard',
        serviceCode: 'STD',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'Delhivery',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__Delhivery.png',
    carrierCode: 'DLVR',
    pickupCutoffTime: '12:00 PM',
    services: [
      {
        name: 'Surface',
        serviceCode: 'SFC',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'DTDC',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_DTDC.png',
    carrierCode: 'DTDC',
    pickupCutoffTime: '01:00 PM',
    services: [
      {
        name: 'Premium',
        serviceCode: 'PRM',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'Ecom Express',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__EcomExpress.png',
    carrierCode: 'ECMX',
    pickupCutoffTime: '11:00 AM',
    services: [
      {
        name: 'Standard',
        serviceCode: 'STD',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'Ekart',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Ekart.png',
    carrierCode: 'EKRT',
    pickupCutoffTime: '12:00 PM',
    services: [
      {
        name: 'Standard',
        serviceCode: 'STD',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'Ekart',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Ekart.png',
    carrierCode: 'EKRT',
    pickupCutoffTime: '12:00 PM',
    services: [
      {
        name: 'Express',
        serviceCode: 'EXP',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'India Post',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__IndiaPost.png',
    carrierCode: 'INDP',
    pickupCutoffTime: '11:00 AM',
    services: [
      {
        name: 'Speed Post',
        serviceCode: 'SFD',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'India Post',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__IndiaPost.png',
    carrierCode: 'INDP',
    pickupCutoffTime: '11:00 AM',
    services: [
      {
        name: 'Pickup',
        serviceCode: 'SPP',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'Movin',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_movin.png',
    carrierCode: 'MOVN',
    pickupCutoffTime: '01:00 PM',
    services: [
      {
        name: 'Standard Premium',
        serviceCode: 'STD',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'Movin',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_movin.png',
    carrierCode: 'MOVN',
    pickupCutoffTime: '01:00 PM',
    services: [
      {
        name: 'Express',
        serviceCode: 'EXP',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'ShadowFax',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__Shadowfax.png',
    carrierCode: 'SDFX',
    pickupCutoffTime: '01:00 PM',
    services: [
      {
        name: 'Standard',
        serviceCode: 'STD',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'Shree Maruti',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_MarutiCourierServices_New.png',
    carrierCode: 'SMCS',
    pickupCutoffTime: '01:00 PM',
    services: [
      {
        name: 'Surface',
        serviceCode: 'SFC',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'Smartr',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS__Smartr.png',
    carrierCode: 'SMTR',
    pickupCutoffTime: '04:30 PM',
    services: [
      {
        name: 'Express',
        serviceCode: 'EXP',
        modesOfTransport: ['AIR']
      }
    ]
  },
  {
    name: 'XpressBees',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Xpressbees.png',
    carrierCode: 'XPBS',
    pickupCutoffTime: '11:00 AM',
    services: [
      {
        name: 'Surface',
        serviceCode: 'SFC',
        modesOfTransport: ['SURFACE']
      }
    ]
  },
  {
    name: 'XpressBees',
    logo: 'https://res.cloudinary.com/coreyo-cdn/image/upload/assets/provider-logos/PARTNERBRAND_LOGOS_Xpressbees.png',
    carrierCode: 'XPBS',
    pickupCutoffTime: '11:00 AM',
    services: [
      {
        name: 'Cargo',
        serviceCode: 'CRG',
        modesOfTransport: ['SURFACE']
      }
    ]
  }
];
