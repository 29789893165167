import React, { Dispatch, FC, SetStateAction } from 'react';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';
import { Modal, Tag } from 'antd';
import { CLOUDINARY_URL } from '../../../../../utils/config';
import { IBulkOrder } from '../../../../../interfaces/b2bBulkOrder';

interface IBasicDetails {
  selectedOrder: IBulkOrder | undefined;
  showBasicDetailsModal: boolean;
  setShowBasicDetailsModal: Dispatch<SetStateAction<boolean>>;
  setSelectedOrder: Dispatch<SetStateAction<IBulkOrder | undefined>>;
}

const BasicDetails: FC<IBasicDetails> = ({
  selectedOrder,
  setSelectedOrder,
  showBasicDetailsModal,
  setShowBasicDetailsModal
}) => {
  const onClose = () => {
    setSelectedOrder(undefined);
    setShowBasicDetailsModal(false);
  };

  return (
    <Modal
      footer={false}
      width="80%"
      open={showBasicDetailsModal}
      onCancel={onClose}
      title={`Basic Details - ${selectedOrder?.id}`}>
      <div className="flex gap-2 flex-wrap mt-5">
        <div className="flex flex-col gap-2 w-full lg:w-[48%]">
          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Order Id <span>:</span>
            </p>
            {selectedOrder?.id ? <GetCopyTextDiv text={selectedOrder?.id} /> : 'NA'}
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Created At <span>:</span>
            </p>
            <p>
              {selectedOrder?.createdAt
                ? moment(selectedOrder?.createdAt).format('YYYY-MM-DD, HH:mm:ss')
                : 'NA'}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Insurance Id <span>:</span>
            </p>
            {selectedOrder?.insuranceId ? (
              <GetCopyTextDiv text={selectedOrder?.insuranceId} />
            ) : (
              'NA'
            )}
          </div>

          <div className="flex gap-2 items-start">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Client Order Reference Id <span>:</span>
            </p>
            {selectedOrder?.clientOrderReferenceId ? (
              <GetCopyTextDiv text={selectedOrder?.clientOrderReferenceId} />
            ) : (
              'NA'
            )}
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Bulk Order Id <span>:</span>
            </p>
            {selectedOrder?.bulkOrderId ? (
              <GetCopyTextDiv text={selectedOrder?.bulkOrderId} />
            ) : (
              'NA'
            )}
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Order Type <span>:</span>
            </p>
            <Tag>{selectedOrder?.orderType || 'NA'}</Tag>
          </div>

          <div className="flex gap-2">
            <p className="font-semibold w-[150px] flex gap-1 justify-between">
              Payment Type <span>:</span>
            </p>

            <div className="flex items-center gap-1">
              <Tag>{selectedOrder?.paymentType || 'NA'}</Tag>

              {selectedOrder?.paymentType === 'BNPL' && (
                <img
                  src={`${CLOUDINARY_URL}/ccd-icons/post-paid-payment.png`}
                  alt=""
                  className="w-8 h-8"
                />
              )}

              {selectedOrder?.paymentType === 'WALLET' && (
                <img
                  src={`${CLOUDINARY_URL}/ccd-icons/wallet-payment.png`}
                  alt=""
                  className="w-8 h-8"
                />
              )}
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Source <span>:</span>
              </p>
              <Tag>{selectedOrder?.source?.split('_')?.join(' ') || 'NA'}</Tag>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full lg:w-[48%]">
          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Status <span>:</span>
              </p>
              <Tag>{selectedOrder?.status?.split('_')?.join(' ') || 'NA'}</Tag>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Total Dimension <span>:</span>
              </p>
              <div className="flex flex-wrap gap-1 font-semibold">
                <p>{selectedOrder?.totalLength} ( L )</p>
                <p>--</p>
                <p>{selectedOrder?.totalWidth} ( W )</p>
                <p>--</p>
                <p>{selectedOrder?.totalHeight} ( H )</p>
              </div>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Total Weight <span>:</span>
              </p>
              <p>{selectedOrder?.totalWeight}</p>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Total Partners <span>:</span>
              </p>
              <p>{selectedOrder?.totalPartners}</p>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Total Product Value <span>:</span>
              </p>
              <p>{selectedOrder?.totalProductValue}</p>
            </div>
          </div>

          <div className="flex gap-2">
            <div className="flex gap-2">
              <p className="font-semibold w-[150px] flex gap-1 justify-between">
                Tracking Status <span>:</span>
              </p>
              <Tag>{selectedOrder?.trackingStatus || 'NA'}</Tag>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BasicDetails;
