import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';
import { IB2BBulkOrderDetails } from '../../../../../interfaces/b2bBulkOrder';

export const getB2BBulkOrderDetails = async ({
  setLoading,
  setData,
  bulkId,
  pageLimit,
  currentPage
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IB2BBulkOrderDetails | undefined>>;
  bulkId: string;
  pageLimit: number;
  currentPage: number;
}) => {
  setLoading(true);
  const offSet = pageLimit * (currentPage - 1);
  const queryParams = `?offset=${offSet}&limit=${pageLimit}`;

  try {
    const response = await httpClient.get(`/business-bulk/${bulkId}${queryParams}`);
    setData(response?.data?.data?.bulkOrder);
  } catch (error: any) {
    setData(undefined);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B order details:', error);
  } finally {
    setLoading(false);
  }
};

export const getShippingLabel = async ({
  setLoading,
  bulkId
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  bulkId: string;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`/business-bulk/${bulkId}/labels`);
    window.open(response?.data?.data?.shippingLabelUrl, '_blank');
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B order details:', error);
  } finally {
    setLoading(false);
  }
};

export const getBookingReport = async ({
  setLoading,
  bulkId
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  bulkId: string;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`/business-bulk/${bulkId}/report`);
    window.open(response?.data?.data?.reportUrl, '_blank');
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching B2B order details:', error);
  } finally {
    setLoading(false);
  }
};
