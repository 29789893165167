import React, { FC } from 'react';
import { Avatar, Typography } from 'antd';
import { FaUserTie } from 'react-icons/fa';
import { IBulkOrder } from '../../../../../interfaces/b2bBulkOrder';

interface IUserDetails {
  ordersData: IBulkOrder | undefined;
}
const UserDetails: FC<IUserDetails> = ({ ordersData }) => {
  return (
    <>
      <h1 className="font-semibold text-xl m-0">User Details</h1>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col md:flex-row gap-4 items-center">
          {ordersData?.user && Object.keys(ordersData?.user).length > 0 && (
            <div className="w-[50px]">
              <Avatar
                size={50}
                shape="square"
                src={ordersData?.user?.profilePicture}
                icon={
                  <div className="flex items-center justify-center pt-3">
                    <FaUserTie />
                  </div>
                }
              />
            </div>
          )}

          <div className="flex flex-col gap-1">
            {(ordersData?.user?.firstName || ordersData?.user?.lastName) && (
              <h2 className="text-xl font-medium">
                {`${ordersData?.user?.firstName || ''} ${ordersData?.user?.lastName || ''}`.trim()}
              </h2>
            )}
            <div className="flex items-center gap-1 flex-wrap">
              <p className="font-semibold">User ID:</p>
              {ordersData?.user?.id ? (
                <Typography.Text
                  className="font-medium flex items-center"
                  copyable={{ text: ordersData?.user?.id }}>
                  {ordersData?.user?.id}
                </Typography.Text>
              ) : (
                <p>NA</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
