import { Button, Form, Input, Select, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { ICountry, IPostalCodeDetailsResponse } from '../../../../../interfaces/b2bPlacesDetails';
import { getPostalCodeDetails } from '../api';

interface IPostalCodeForm {
  countryCode: string;
  postalCode: string;
}

interface IPostalCodeDetails {
  countriesList: ICountry[];
  countryListLoading: boolean;
}
const PostalCodeDetails: FC<IPostalCodeDetails> = ({ countriesList, countryListLoading }) => {
  const [postalCodeDetails] = Form.useForm<IPostalCodeForm>();
  const [countriesOption, setCountriesOption] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [postalLoading, setPostalLoading] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<IPostalCodeDetailsResponse>();

  useEffect(() => {
    if (countriesList) {
      const countryOptions = countriesList?.map((item) => ({
        label: `${item?.flag?.emoji} - ${item?.name} - ( ${item?.codes?.iso2} )`,
        value: item?.codes?.iso2
      }));

      setCountriesOption(countryOptions);
    }
  }, [countriesList]);

  const onSelectChange = () => {
    postalCodeDetails.resetFields(['postalCode']);
  };

  const onSubmit = async (formValues: IPostalCodeForm) => {
    await getPostalCodeDetails({
      setLoading: setPostalLoading,
      countryCode: formValues?.countryCode,
      postalCode: formValues?.postalCode,
      setData: setDetailsData
    });
  };

  return (
    <Spin spinning={countryListLoading || postalLoading}>
      <h1 className="text-lg mb-5">Postal Code Details</h1>

      <div className="flex gap-7 flex-col lg:flex-row">
        <Form
          disabled={countriesOption?.length === 0}
          scrollToFirstError
          form={postalCodeDetails}
          id="postalCodeDetails"
          onFinish={onSubmit}
          className="max-w-[600px] w-full lg:w-1/2">
          <div className="flex flex-col gap-2">
            <Formlabel labelText="Select Country" />
            <Form.Item
              name="countryCode"
              rules={[{ required: true, message: 'Please select any country' }]}>
              <Select
                allowClear
                options={countriesOption}
                showSearch
                optionFilterProp="label"
                placeholder="Select country"
                onChange={onSelectChange}
              />
            </Form.Item>
          </div>

          <div className="flex flex-col gap-2">
            <Formlabel labelText="Enter Postal Code" />
            <Form.Item
              name="postalCode"
              rules={[{ required: true, message: 'Please select any country' }]}>
              <Input placeholder="Enter Postal Code" />
            </Form.Item>
          </div>

          <Button
            disabled={countryListLoading || postalLoading}
            className="primary-button mt-3 mb-5"
            form="postalCodeDetails"
            htmlType="submit">
            Submit
          </Button>
        </Form>
        {detailsData && (
          <div className="max-w-[600px] w-full lg:w-1/2 primary-box-shadow px-4 py-3 rounded-xl">
            <h1 className="text-lg mb-3 font-semibold">Details - {detailsData?.postalCode}</h1>

            <div className="flex flex-col gap-2">
              <div className="flex gap-3">
                <p className="flex gap-3 justify-between w-[100px] font-semibold">
                  City <span>:</span>
                </p>
                <p>{detailsData?.city || 'NA'}</p>
              </div>

              <div className="flex gap-3">
                <p className="flex gap-3 justify-between w-[100px] font-semibold">
                  District <span>:</span>
                </p>
                <p>{detailsData?.district || 'NA'}</p>
              </div>

              <div className="flex gap-3">
                <p className="flex gap-3 justify-between w-[100px] font-semibold">
                  State <span>:</span>
                </p>
                <p>{`${detailsData?.state || 'NA'} ${
                  detailsData?.stateCode ? `- ${detailsData?.stateCode}` : ''
                }`}</p>
              </div>

              <div className="flex gap-3">
                <p className="flex gap-3 justify-between w-[100px] font-semibold">
                  Country <span>:</span>
                </p>
                <p>{`${detailsData?.country || 'NA'} ${
                  detailsData?.countryCode ? `- ${detailsData?.countryCode}` : ''
                }`}</p>
              </div>

              <div className="flex gap-3">
                <p className="flex gap-3 justify-between w-[100px] font-semibold">
                  Postal Code <span>:</span>
                </p>
                <p>{detailsData?.postalCode || 'NA'}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default PostalCodeDetails;
