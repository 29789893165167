import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, Spin, Switch, Tooltip } from 'antd';
import {
  IAddressList,
  ICountry,
  IEditAddressFormFields,
  IStateList
} from '../../../../../interfaces/b2bAddressBook';
import { formValidations } from '../utils';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { editAddressAPI, getCountriesList, getStatesList } from '../api';
import { ErrorMessage } from '../../../../../utils/ResponseMessages';

interface IEditAddressModal {
  editAddress: IAddressList | undefined;
  editAddressModal: boolean;
  setEditAddressModal: Dispatch<SetStateAction<boolean>>;
  handleGetAllAddress: () => void;
}

const EditAddressModal: FC<IEditAddressModal> = ({
  editAddress,
  editAddressModal,
  setEditAddressModal,
  handleGetAllAddress
}) => {
  const [editAddressForm] = Form.useForm();
  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const [editAddressLoading, setEditAddressLoading] = useState<boolean>(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const modalWidth = window.innerWidth <= 1024 ? '90%' : '800px';

  const handleCountryList = async () => {
    await getCountriesList({ setData: setCountriesList });
  };

  const handleStateList = async () => {
    await getStatesList({
      setData: setStatesData,
      countryCode: editAddress?.countryCode || '',
      setLoading: setStateLoading
    });
  };

  useEffect(() => {
    if (editAddressModal) {
      handleCountryList();
      handleStateList();
    }
  }, [editAddressModal]);

  useEffect(() => {
    if (editAddressModal && editAddress) {
      editAddressForm.setFieldsValue({
        name: editAddress?.name,
        companyName: editAddress?.companyName,
        line1: editAddress?.line1,
        line2: editAddress?.line2,
        line3: editAddress?.line3,
        isdCode: editAddress?.isdCode,
        email: editAddress?.email,
        mobileNo: editAddress?.mobileNo,
        alternatePhoneNo: editAddress?.alternatePhoneNo,
        countryCode: editAddress?.countryCode,
        stateCode: editAddress?.stateCode,
        city: editAddress?.city,
        postalCode: editAddress?.postalCode,
        tag: editAddress?.tag,
        default: editAddress?.default
      });

      setIsDefault(editAddress?.default);
    }
  }, [editAddress, editAddressModal]);

  const onSubmit = async (formValues: IEditAddressFormFields) => {
    const { isdCode, countryCode, stateCode, postalCode, city, ...payload } = {
      ...formValues,
      default: isDefault
    };

    let updatedPayload: IEditAddressFormFields = {};

    if (formValues?.name?.trim() !== editAddress?.name?.trim()) {
      updatedPayload = {
        ...updatedPayload,
        name: formValues?.name
      };
    }

    if (
      formValues?.companyName &&
      formValues?.companyName?.trim() !== editAddress?.companyName?.trim()
    ) {
      updatedPayload = {
        ...updatedPayload,
        companyName: formValues?.companyName
      };
    }

    if (formValues?.line1?.trim() !== editAddress?.line1?.trim()) {
      updatedPayload = {
        ...updatedPayload,
        line1: formValues?.line1
      };
    }

    if (formValues?.line2 && formValues?.line2?.trim() !== editAddress?.line2?.trim()) {
      updatedPayload = {
        ...updatedPayload,
        line2: formValues?.line2
      };
    }

    if (formValues?.line3 && formValues?.line3?.trim() !== editAddress?.line3?.trim()) {
      updatedPayload = {
        ...updatedPayload,
        line3: formValues?.line3
      };
    }

    if (formValues?.email && formValues?.email !== editAddress?.email) {
      updatedPayload = {
        ...updatedPayload,
        email: formValues?.email
      };
    }

    if (formValues?.mobileNo !== editAddress?.mobileNo) {
      updatedPayload = {
        ...updatedPayload,
        mobileNo: formValues?.mobileNo
      };
    }

    if (
      formValues?.alternatePhoneNo &&
      formValues?.alternatePhoneNo !== editAddress?.alternatePhoneNo
    ) {
      updatedPayload = {
        ...updatedPayload,
        alternatePhoneNo: formValues?.alternatePhoneNo
      };
    }

    if (formValues?.tag?.trim() !== editAddress?.tag?.trim()) {
      updatedPayload = {
        ...updatedPayload,
        tag: formValues?.tag
      };
    }

    if (isDefault !== editAddress?.default) {
      updatedPayload = {
        ...updatedPayload,
        default: isDefault
      };
    }

    if (Object.keys(updatedPayload)?.length === 0) {
      ErrorMessage('Please edit some fields.');
    } else {
      await editAddressAPI({
        setLoading: setEditAddressLoading,
        businessId: editAddress?.businessId || '',
        addressId: editAddress?.id || '',
        payload: updatedPayload,
        callBackFun: () => onClose(true)
      });
    }
  };

  const checkOnClose = () => {
    if (!editAddressLoading) {
      onClose();
    }
  };
  const onClose = (fetchAll = false) => {
    setEditAddressModal(false);
    editAddressForm.resetFields();

    if (fetchAll) {
      handleGetAllAddress();
    }
  };

  return (
    <Modal
      onCancel={checkOnClose}
      width={modalWidth}
      title="Edit Address"
      open={editAddressModal}
      footer={false}>
      <Spin spinning={editAddressLoading}>
        <Form onFinish={onSubmit} form={editAddressForm} scrollToFirstError layout="vertical">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <Formlabel labelText="Name" required />
              <Form.Item
                className="m-0"
                name="name"
                rules={[
                  { required: true, message: formValidations?.name?.requiredMessage },
                  {
                    pattern: formValidations?.name?.regexPattern,
                    message: formValidations?.name?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Name" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Company Name" />
              <Form.Item
                className="m-0"
                name="companyName"
                rules={[
                  {
                    pattern: formValidations?.companyName?.regexPattern,
                    message: formValidations?.companyName?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Company Name" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Address :" />
              <Formlabel labelText="Line 1" required />
              <Form.Item
                className="m-0"
                name="line1"
                rules={[
                  { required: true, message: formValidations?.address?.requiredMessage },
                  {
                    pattern: formValidations?.address?.regexPattern,
                    message: formValidations?.address?.regexMessage
                  },
                  {
                    pattern: formValidations?.address?.maxCharacterPattern,
                    message: formValidations?.address?.maxCharacterMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Address Line 1" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Line 2" />
              <Form.Item
                className="m-0"
                name="line2"
                rules={[
                  {
                    pattern: formValidations?.address?.regexPattern,
                    message: formValidations?.address?.regexMessage
                  },
                  {
                    pattern: formValidations?.address?.maxCharacterPattern,
                    message: formValidations?.address?.maxCharacterMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Address Line 2" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Line 3" />
              <Form.Item
                className="m-0"
                name="line3"
                rules={[
                  {
                    pattern: formValidations?.address?.regexPattern,
                    message: formValidations?.address?.regexMessage
                  },
                  {
                    pattern: formValidations?.address?.maxCharacterPattern,
                    message: formValidations?.address?.maxCharacterMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Address Line 3" />
              </Form.Item>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="ISD Code" required />
                <Form.Item
                  className="m-0"
                  name="isdCode"
                  rules={[
                    { required: true, message: formValidations?.isdCode?.requiredMessage },
                    {
                      pattern: formValidations?.isdCode?.regexPattern,
                      message: formValidations?.isdCode?.regexMessage
                    }
                  ]}>
                  <Input disabled type="text" placeholder="Enter Code" />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Email" />
                <Form.Item
                  className="m-0"
                  name="email"
                  rules={[
                    {
                      pattern: formValidations?.email?.regexPattern,
                      message: formValidations?.email?.regexMessage
                    }
                  ]}>
                  <Input type="text" placeholder="Enter Email" />
                </Form.Item>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Mobile No" required />
                <Form.Item
                  className="m-0"
                  name="mobileNo"
                  rules={[
                    { required: true, message: formValidations?.mobileNo?.requiredMessage },
                    {
                      pattern: formValidations?.mobileNo?.regexPattern,
                      message: formValidations?.mobileNo?.regexMessage
                    }
                  ]}>
                  <Input type="number" placeholder="Enter Mobile Number" />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Alternate Phone No" />
                <Form.Item
                  className="m-0"
                  name="alternatePhoneNo"
                  rules={[
                    {
                      pattern: formValidations?.mobileNo?.regexPattern,
                      message: formValidations?.mobileNo?.regexMessage
                    }
                  ]}>
                  <Input type="number" placeholder="Enter Alternate Phone No" />
                </Form.Item>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Country" required />
                <Form.Item
                  className="m-0"
                  name="countryCode"
                  rules={[{ required: true, message: formValidations?.country?.requiredMessage }]}>
                  <Select
                    showSearch
                    disabled
                    options={countriesList?.map((item) => ({
                      label: `${item?.name[0]} - ${item?.codes?.iso2}`,
                      value: item?.codes?.iso2
                    }))}
                    optionFilterProp="label"
                    placeholder="Select any country"
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="State" required />
                <Tooltip
                  title={`${
                    stateLoading
                      ? 'Fetching states, please wait'
                      : statesData?.length
                      ? ''
                      : 'Select country'
                  }`}>
                  <Form.Item
                    className="m-0"
                    name="stateCode"
                    rules={[{ required: true, message: formValidations?.state?.requiredMessage }]}>
                    <Select
                      showSearch
                      disabled
                      placeholder="Select any state"
                      optionFilterProp="label"
                      options={statesData?.map((item) => ({
                        label: `${item?.name} - ${item?.code}`,
                        value: item?.code
                      }))}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="City" required />
                <Form.Item
                  className="m-0"
                  name="city"
                  rules={[{ required: true, message: formValidations?.city?.requiredMessage }]}>
                  <Input disabled type="text" placeholder="Enter any city" />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Postal Code" required />
                <Form.Item
                  className="m-0"
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: formValidations?.postalCode?.requiredMessage
                    },
                    {
                      pattern: formValidations?.postalCode?.regexPattern,
                      message: formValidations?.postalCode?.regexMessage
                    }
                  ]}>
                  <Input disabled type="text" placeholder="Enter Postal Code" />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Address Tag Name" required />
              <Form.Item
                className="m-0"
                name="tag"
                rules={[
                  {
                    required: true,
                    message: formValidations?.tag?.requiredMessage
                  },
                  {
                    pattern: formValidations?.tag?.regexPattern,
                    message: formValidations?.tag?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter any tag" />
              </Form.Item>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3 w-full md:w-1/2">
                <Formlabel labelText="Default" />
                <div>
                  <Switch
                    checked={isDefault}
                    onChange={(value) => setIsDefault(value)}
                    className="bg-gray-300"
                  />
                </div>
              </div>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditAddressModal;
