import { ECBO_ADDRESS_LENGTH, ECBO_NAME_LENGTH } from '../../../../../../utils/config';

export const validateColumnKeys = {
  order_id: 'Order Id',
  delivery_address_line1: 'Delivery Address Line 1',
  delivery_address_line2: 'Delivery address line 2',
  delivery_email: 'Delivery Email',
  delivery_customer_name: 'Delivery Customer Name',

  delivery_address_country: 'Delivery Address Country',
  delivery_address_state: 'Delivery Address State',
  delivery_address_city: 'Delivery Address City',

  delivery_address_pincode: 'Delivery Address Pincode',
  delivery_phone_number: 'Delivery Phone Number',

  billing_customer_name: 'Billing Customer Name',
  billing_address_line1: 'Billing Address Line 1',
  billing_address_line2: 'Billing Address Line 2',

  billing_address_city: 'Billing Address City',
  billing_address_state: 'Billing Address State',
  billing_address_country: 'Billing Address Country',

  billing_address_pincode: 'Billing Address Pincode',
  billing_phone_number: 'Billing Phone Number',
  billing_email: 'Billing Email',

  shipment_length: 'Shipment Length',
  shipment_width: 'Shipment Width',
  shipment_height: 'Shipment Height',
  weight: 'Weight',
  item_description: 'Item Description',
  estimated_value: 'Estimated Value',

  service_type: 'Service Type',
  delivery_vendor: 'Delivery Vendor',

  item_category: 'Item Category'
};

export const checkValidation = (order: { [key: string]: any }) => {
  let invalidOrder: string[] = [];
  let invalidOrderKeys: string[] = [];

  // eslint-disable-next-line
  const specialCharPattern = /[@_!#$%^&*()<>?\|}{~:]/g;
  const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const indianPincodeRegex = /^\d{6}$/;
  const phoneNumberRegex = /^[6-9]\d{9}$/;
  const numberRegex = /^[1-9][0-9]*$/;
  const measurementRegex = /^-?\d+(\.\d+)?$/;

  const appendError = (condition: any, message: string, keys: string) => {
    if (condition) {
      invalidOrder = [...invalidOrder, message];
      invalidOrderKeys = [...invalidOrderKeys, keys];
    }
  };

  appendError(
    order?.order_id === '' || order?.order_id === undefined || order?.order_id === null,
    `${validateColumnKeys?.order_id} : Required.`,
    validateColumnKeys?.order_id
  );

  appendError(
    order?.item_category === '' ||
      order?.item_category === undefined ||
      order?.item_category === null,
    `${validateColumnKeys?.item_category} : Required.`,
    validateColumnKeys?.item_category
  );

  appendError(
    order?.service_type === '' || order?.service_type === undefined || order?.service_type === null,
    `${validateColumnKeys?.service_type} : Required.`,
    validateColumnKeys?.service_type
  );

  appendError(
    order?.delivery_vendor === '' ||
      order?.delivery_vendor === undefined ||
      order?.delivery_vendor === null,
    `${validateColumnKeys?.delivery_vendor} : Required.`,
    validateColumnKeys?.delivery_vendor
  );

  appendError(
    specialCharPattern?.test(order?.delivery_address_line1),
    `${validateColumnKeys?.delivery_address_line1} : Remove ${order?.delivery_address_line1
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.delivery_address_line1
  );

  appendError(
    order?.delivery_address_line1?.length > Number(ECBO_ADDRESS_LENGTH),
    `${validateColumnKeys?.delivery_address_line1} : Characters length should not be more than 60.`,
    validateColumnKeys?.delivery_address_line1
  );

  appendError(
    order?.delivery_address_line1?.toString()?.trim()?.length === 0 ||
      order?.delivery_address_line1?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.delivery_address_line1} : Please add address.`,
    validateColumnKeys?.delivery_address_line1
  );

  appendError(
    specialCharPattern?.test(order?.delivery_address_line2),
    `${validateColumnKeys?.delivery_address_line2} : Remove ${order?.delivery_address_line2
      ?.toString()
      ?.match(specialCharPattern)}`,
    validateColumnKeys?.delivery_address_line2
  );

  appendError(
    order?.delivery_address_line2?.length > Number(ECBO_ADDRESS_LENGTH),
    `${validateColumnKeys?.delivery_address_line2} : Characters length should not be more than 60.`,
    validateColumnKeys?.delivery_address_line2
  );

  appendError(
    order?.delivery_address_line2?.toString()?.trim()?.length === 0 ||
      order?.delivery_address_line2?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.delivery_address_line2} : Please add address.`,
    validateColumnKeys?.delivery_address_line2
  );

  appendError(
    !emailRegex?.test(order?.delivery_email),
    `${validateColumnKeys?.delivery_email} : Invalid email id.`,
    validateColumnKeys?.delivery_email
  );

  appendError(
    order?.delivery_customer_name?.length === 0 ||
      order?.delivery_customer_name?.length === undefined ||
      order?.delivery_customer_name?.length > Number(ECBO_NAME_LENGTH),
    `${validateColumnKeys?.delivery_customer_name} : ${
      order?.delivery_customer_name?.length === 0 ||
      order?.delivery_customer_name?.length === undefined
        ? 'Please add customer name.'
        : `Characters length should not be more than ${ECBO_NAME_LENGTH}.`
    }`,
    validateColumnKeys?.delivery_customer_name
  );

  appendError(
    order?.delivery_address_city === '' ||
      order?.delivery_address_city === undefined ||
      order?.delivery_address_city === null,
    `${validateColumnKeys?.delivery_address_city} : Required.`,
    validateColumnKeys?.delivery_address_city
  );
  appendError(
    order?.delivery_address_state === '' ||
      order?.delivery_address_state === undefined ||
      order?.delivery_address_state === null,
    `${validateColumnKeys?.delivery_address_state} : Required.`,
    validateColumnKeys?.delivery_address_state
  );
  appendError(
    order?.delivery_address_country === '' ||
      order?.delivery_address_country === undefined ||
      order?.delivery_address_country === null,
    `${validateColumnKeys?.delivery_address_country} : Required.`,
    validateColumnKeys?.delivery_address_country
  );
  appendError(
    // !indianPincodeRegex?.test(order?.delivery_address_pincode),
    !order?.delivery_address_pincode,
    `${validateColumnKeys?.delivery_address_pincode} : Please add pincode.`,
    validateColumnKeys?.delivery_address_pincode
  );

  appendError(
    !phoneNumberRegex?.test(order?.delivery_phone_number),
    `${validateColumnKeys?.delivery_phone_number} : Invalid phone number.`,
    validateColumnKeys?.delivery_phone_number
  );

  // billing data validations
  appendError(
    order?.billing_customer_name?.length === 0 ||
      order?.billing_customer_name?.length === undefined ||
      order?.billing_customer_name?.length > Number(ECBO_NAME_LENGTH),
    `${validateColumnKeys?.billing_customer_name} : ${
      order?.billing_customer_name?.length === 0 ||
      order?.billing_customer_name?.length === undefined
        ? 'Please add customer name.'
        : `Characters length should not be more than ${ECBO_NAME_LENGTH}.`
    }`,
    validateColumnKeys?.billing_customer_name
  );

  appendError(
    specialCharPattern?.test(order?.billing_address_line1),
    `${validateColumnKeys?.billing_address_line1} : Remove ${order?.billing_address_line1
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.billing_address_line1
  );

  appendError(
    order?.billing_address_line1?.length > Number(ECBO_ADDRESS_LENGTH),
    `${validateColumnKeys?.billing_address_line1} : Characters length should not be more than 60.`,
    validateColumnKeys?.billing_address_line1
  );

  appendError(
    order?.billing_address_line1?.toString()?.trim()?.length === 0 ||
      order?.billing_address_line1?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.billing_address_line1} : Please add address.`,
    validateColumnKeys?.billing_address_line1
  );

  appendError(
    specialCharPattern?.test(order?.billing_address_line2),
    `${validateColumnKeys?.billing_address_line2} : Remove ${order?.billing_address_line2
      ?.toString()
      ?.match(specialCharPattern)}.`,
    validateColumnKeys?.billing_address_line2
  );

  appendError(
    order?.billing_address_line2?.length > Number(ECBO_ADDRESS_LENGTH),
    `${validateColumnKeys?.billing_address_line2} : Characters length should not be more than 60.`,
    validateColumnKeys?.billing_address_line2
  );

  appendError(
    order?.billing_address_line2?.toString()?.trim()?.length === 0 ||
      order?.billing_address_line2?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.billing_address_line2} : Please add address.`,
    validateColumnKeys?.billing_address_line2
  );

  appendError(
    order?.billing_address_city === '' ||
      order?.billing_address_city === undefined ||
      order?.billing_address_city === null,
    `${validateColumnKeys?.billing_address_city} : Required.`,
    validateColumnKeys?.billing_address_city
  );

  appendError(
    order?.billing_address_state === '' ||
      order?.billing_address_state === undefined ||
      order?.billing_address_state === null,
    `${validateColumnKeys?.billing_address_state} : Required.`,
    validateColumnKeys?.billing_address_state
  );

  appendError(
    order?.billing_address_country === '' ||
      order?.billing_address_country === undefined ||
      order?.billing_address_country === null,
    `${validateColumnKeys?.billing_address_country} : Required.`,
    validateColumnKeys?.billing_address_country
  );

  appendError(
    // !indianPincodeRegex?.test(order?.billing_address_pincode),
    !order?.billing_address_pincode,
    `${validateColumnKeys?.billing_address_pincode} : Please add pincode.`,
    validateColumnKeys?.billing_address_pincode
  );

  appendError(
    !phoneNumberRegex?.test(order?.billing_phone_number),
    `${validateColumnKeys?.billing_phone_number} : Invalid phone number.`,
    validateColumnKeys?.billing_phone_number
  );

  appendError(
    !emailRegex?.test(order?.billing_email),
    `${validateColumnKeys?.billing_email} : Invalid email id.`,
    validateColumnKeys?.billing_email
  );

  appendError(
    !measurementRegex?.test(order?.shipment_length),
    `${validateColumnKeys?.shipment_length} : Invalid length.`,
    validateColumnKeys?.shipment_length
  );

  appendError(
    !measurementRegex?.test(order?.shipment_width),
    `${validateColumnKeys?.shipment_width} : Invalid width.`,
    validateColumnKeys?.shipment_width
  );

  appendError(
    !measurementRegex?.test(order?.shipment_height),
    `${validateColumnKeys?.shipment_height} : Invalid height.`,
    validateColumnKeys?.shipment_height
  );

  appendError(
    !measurementRegex?.test(order?.weight),
    `${validateColumnKeys?.weight} : Invalid weight.`,
    validateColumnKeys?.weight
  );

  appendError(
    order?.item_description?.toString()?.trim()?.length === 0 ||
      order?.item_description?.toString()?.trim()?.length === undefined,
    `${validateColumnKeys?.item_description} : Please add item description.`,
    validateColumnKeys?.item_description
  );

  appendError(
    order?.estimated_value === '' ||
      order?.estimated_value === 0 ||
      !numberRegex?.test(order?.estimated_value),
    `${validateColumnKeys?.estimated_value} : ${
      !numberRegex?.test(order?.estimated_value)
        ? 'Invalid value.'
        : order?.estimated_value === 0 || order?.estimated_value === ''
        ? 'Please add estimated value.'
        : 'Value must be greater than 0 and less than 50,000.'
    } `,
    validateColumnKeys?.estimated_value
  );

  return { invalidOrder, invalidOrderKeys };
};
