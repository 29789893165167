import { httpClient } from '../../../../utils/apiRequest';
import { showMessage } from '../../../../utils/ResponseMessages';

export const listAllFixedCharges = async ({ body, setLoading }: { body: any; setLoading: any }) => {
  try {
    setLoading(true);
    const response = await httpClient.post(`/fintech-orders/list-all-fixed-charges`, body);
    return response?.data?.data.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};

export const createFixedCharges = async ({ body, setLoading }: { body: any; setLoading: any }) => {
  try {
    setLoading(true);
    const response = await httpClient.post(`/fintech-orders/fixed-charges`, body);
    showMessage(response?.data?.message, response?.status);
    return response?.data?.data;
  } catch (error: any) {
    showMessage(error?.response?.data?.message, error?.response?.status);
  } finally {
    setLoading(false);
  }
};
