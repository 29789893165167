import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

const GetCopyTextDiv = ({ text, textClassName }: { text?: string; textClassName?: string }) => {
  return text ? (
    <div className="flex items-center gap-1 flex-wrap">
      <p className={`m-0 ${textClassName}`}>
        <b>{text}</b>
      </p>
      <Text copyable={{ text: text }} />
    </div>
  ) : (
    <p>NA</p>
  );
};

export default GetCopyTextDiv;
