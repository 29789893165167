import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Countries from './Countries';
import { ICountry } from '../../../../interfaces/b2bPlacesDetails';
import PostalCodeDetails from './PostalCodeDetails';

const B2BPlaceDetails = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [countryListLoading, setCountryListLoading] = useState<boolean>(false);

  useEffect(() => {
    if (containerRef.current) {
      const tabs = containerRef.current.getElementsByClassName('ant-tabs-tab');
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i] as HTMLDivElement;
        tab.style.width = '100%';
        tab.style.textAlign = 'center';
      }
    }
  }, []);

  const items: TabsProps['items'] = [
    {
      label: (
        <h1 className="text-lg">{`Countries ${
          countriesList?.length ? `( ${countriesList?.length} )` : ''
        }`}</h1>
      ),
      key: 'Countries',
      children: (
        <Countries
          countriesList={countriesList}
          setCountriesList={setCountriesList}
          countryListLoading={countryListLoading}
          setCountryListLoading={setCountryListLoading}
        />
      )
    },
    {
      label: <h1 className="text-lg">Postal Code</h1>,
      key: 'PostalCode',
      children: (
        <PostalCodeDetails countriesList={countriesList} countryListLoading={countryListLoading} />
      )
    }
  ];

  return (
    <>
      <h2 className="text-xl font-normal m-0 mb-5">Search Place</h2>

      <div ref={containerRef} className="tabs-container relative">
        <Tabs animated type="card" size="small" className="md:w-[100%] w-full mx-auto p-2 border-0">
          {items.map((item) => (
            <Tabs.TabPane
              key={item.key}
              tab={<div style={{ width: '150px', textAlign: 'center' }}>{item.label}</div>}>
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default B2BPlaceDetails;
