import { Button } from 'antd';
import Modal from 'antd/es/modal/Modal';
import React, { Dispatch, FC, SetStateAction } from 'react';

interface IResetBookingModal {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onClickReset: () => void;
}

const ResetBookingModal: FC<IResetBookingModal> = ({
  isModalOpen,
  setIsModalOpen,
  onClickReset
}) => {
  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal title="Reset Booking" open={isModalOpen} onCancel={onClose} footer={false}>
      <p className="text-lg h-14">
        On reset, you will need to start from step 1. Please confirm to reset.
      </p>

      <div className="flex justify-end gap-2 mt-4">
        <Button className="font-semibold" onClick={onClose}>
          Close
        </Button>
        <Button className="bg-red-500 !text-white font-semibold border-none" onClick={onClickReset}>
          Reset
        </Button>
      </div>
    </Modal>
  );
};

export default ResetBookingModal;
