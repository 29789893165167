import { Button, Form, Input, Modal, Select, Spin, Switch, Tooltip } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { formValidations } from '../utils';
import { createNewAddress, getCountriesList, getStatesList } from '../api';
import { IAddressFormFields, ICountry, IStateList } from '../../../../../interfaces/b2bAddressBook';

interface IAddNewAddress {
  businessId: string;
  addAddressModal: boolean;
  setAddAddressModal: Dispatch<SetStateAction<boolean>>;
  handleGetAllAddress: () => void;
}
const AddNewAddress: FC<IAddNewAddress> = ({
  businessId,
  addAddressModal,
  setAddAddressModal,
  handleGetAllAddress
}) => {
  const [newAddressForm] = Form.useForm();
  const [countriesList, setCountriesList] = useState<ICountry[]>([]);
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const [addAddressLoading, setAddAddressLoading] = useState<boolean>(false);

  const modalWidth = window.innerWidth <= 1024 ? '90%' : '800px';

  const handleCountryList = async () => {
    await getCountriesList({ setData: setCountriesList });
  };

  useEffect(() => {
    if (addAddressModal) {
      handleCountryList();
    }
  }, [addAddressModal]);

  const onCountryChange = async (countryCode: string) => {
    setStatesData([]);
    newAddressForm.resetFields(['stateCode']);
    await getStatesList({
      setData: setStatesData,
      countryCode: countryCode,
      setLoading: setStateLoading
    });
  };

  const onSubmit = async (formValues: IAddressFormFields) => {
    const payload = {
      ...formValues,
      isdCode: Number(formValues?.isdCode),
      default: !formValues?.default || formValues?.default === undefined ? false : true
    };

    await createNewAddress({
      setLoading: setAddAddressLoading,
      payload: payload,
      businessId: businessId,
      callBackFun: () => onClose(true)
    });
  };

  const checkOnClose = () => {
    if (!addAddressLoading) {
      onClose();
    }
  };

  const onClose = (fetchAll = false) => {
    setAddAddressModal(false);
    newAddressForm.resetFields();

    if (fetchAll) {
      handleGetAllAddress();
    }
  };

  return (
    <Modal
      onCancel={checkOnClose}
      width={modalWidth}
      title="Add New Address"
      open={addAddressModal}
      footer={false}>
      <Spin spinning={addAddressLoading}>
        <Form onFinish={onSubmit} form={newAddressForm} scrollToFirstError layout="vertical">
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <Formlabel labelText="Name" required />
              <Form.Item
                className="m-0"
                name="name"
                rules={[
                  { required: true, message: formValidations?.name?.requiredMessage },
                  {
                    pattern: formValidations?.name?.regexPattern,
                    message: formValidations?.name?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Name" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Company Name" />
              <Form.Item
                className="m-0"
                name="companyName"
                rules={[
                  {
                    pattern: formValidations?.companyName?.regexPattern,
                    message: formValidations?.companyName?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Company Name" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Address :" />
              <Formlabel labelText="Line 1" required />
              <Form.Item
                className="m-0"
                name="line1"
                rules={[
                  { required: true, message: formValidations?.address?.requiredMessage },
                  {
                    pattern: formValidations?.address?.regexPattern,
                    message: formValidations?.address?.regexMessage
                  },
                  {
                    pattern: formValidations?.address?.maxCharacterPattern,
                    message: formValidations?.address?.maxCharacterMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Address Line 1" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Line 2" />
              <Form.Item
                className="m-0"
                name="line2"
                rules={[
                  {
                    pattern: formValidations?.address?.regexPattern,
                    message: formValidations?.address?.regexMessage
                  },
                  {
                    pattern: formValidations?.address?.maxCharacterPattern,
                    message: formValidations?.address?.maxCharacterMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Address Line 2" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Line 3" />
              <Form.Item
                className="m-0"
                name="line3"
                rules={[
                  {
                    pattern: formValidations?.address?.regexPattern,
                    message: formValidations?.address?.regexMessage
                  },
                  {
                    pattern: formValidations?.address?.maxCharacterPattern,
                    message: formValidations?.address?.maxCharacterMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter Address Line 3" />
              </Form.Item>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="ISD Code" required />
                <Form.Item
                  className="m-0"
                  name="isdCode"
                  rules={[
                    { required: true, message: formValidations?.isdCode?.requiredMessage },
                    {
                      pattern: formValidations?.isdCode?.regexPattern,
                      message: formValidations?.isdCode?.regexMessage
                    }
                  ]}>
                  <Input type="text" placeholder="Enter Code" />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Email" />
                <Form.Item
                  className="m-0"
                  name="email"
                  rules={[
                    {
                      pattern: formValidations?.email?.regexPattern,
                      message: formValidations?.email?.regexMessage
                    }
                  ]}>
                  <Input type="text" placeholder="Enter Email" />
                </Form.Item>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Mobile No" required />
                <Form.Item
                  className="m-0"
                  name="mobileNo"
                  rules={[
                    { required: true, message: formValidations?.mobileNo?.requiredMessage },
                    {
                      pattern: formValidations?.mobileNo?.regexPattern,
                      message: formValidations?.mobileNo?.regexMessage
                    }
                  ]}>
                  <Input type="number" placeholder="Enter Mobile Number" />
                </Form.Item>
              </div>

              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Alternate Phone No" />
                <Form.Item
                  className="m-0"
                  name="alternatePhoneNo"
                  rules={[
                    {
                      pattern: formValidations?.mobileNo?.regexPattern,
                      message: formValidations?.mobileNo?.regexMessage
                    }
                  ]}>
                  <Input type="number" placeholder="Enter Alternate Phone No" />
                </Form.Item>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Country" required />
                <Form.Item
                  className="m-0"
                  name="countryCode"
                  rules={[{ required: true, message: formValidations?.country?.requiredMessage }]}>
                  <Select
                    onChange={onCountryChange}
                    showSearch
                    options={countriesList?.map((item) => ({
                      label: `${item?.name[0]} - ${item?.codes?.iso2}`,
                      value: item?.codes?.iso2
                    }))}
                    optionFilterProp="label"
                    placeholder="Select any country"
                  />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="State" required />
                <Tooltip
                  title={`${
                    stateLoading
                      ? 'Fetching states, please wait'
                      : statesData?.length
                      ? ''
                      : 'Select country'
                  }`}>
                  <Form.Item
                    className="m-0"
                    name="stateCode"
                    rules={[{ required: true, message: formValidations?.state?.requiredMessage }]}>
                    <Select
                      showSearch
                      disabled={!statesData?.length}
                      placeholder="Select any state"
                      optionFilterProp="label"
                      options={statesData?.map((item) => ({
                        label: `${item?.name} - ${item?.code}`,
                        value: item?.code
                      }))}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
            </div>

            <div className="flex gap-2 flex-col md:flex-row">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="City" required />
                <Form.Item
                  className="m-0"
                  name="city"
                  rules={[{ required: true, message: formValidations?.city?.requiredMessage }]}>
                  <Input type="text" placeholder="Enter any city" />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Postal Code" required />
                <Form.Item
                  className="m-0"
                  name="postalCode"
                  rules={[
                    {
                      required: true,
                      message: formValidations?.postalCode?.requiredMessage
                    },
                    {
                      pattern: formValidations?.postalCode?.regexPattern,
                      message: formValidations?.postalCode?.regexMessage
                    }
                  ]}>
                  <Input type="text" placeholder="Enter Postal Code" />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <Formlabel labelText="Address Tag Name" required />
              <Form.Item
                className="m-0"
                name="tag"
                rules={[
                  {
                    required: true,
                    message: formValidations?.tag?.requiredMessage
                  },
                  {
                    pattern: formValidations?.tag?.regexPattern,
                    message: formValidations?.tag?.regexMessage
                  }
                ]}>
                <Input type="text" placeholder="Enter any tag" />
              </Form.Item>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <Formlabel labelText="Default" />
                <Form.Item className="m-0" name="default">
                  <Switch defaultChecked={false} className="bg-gray-300" />
                </Form.Item>
              </div>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddNewAddress;
