import { Button, Skeleton, Tooltip } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { FaDownload, FaInfoCircle } from 'react-icons/fa';
import { bookBulkOrder, checkServiceability } from '../../api';
import { IBulkUploadUrl, ICheckAvailabilityResponse } from '../../../../../interfaces/b2bBulkOrder';
import { AlertFilled, AlertOutlined } from '@ant-design/icons';
import { BiRefresh } from 'react-icons/bi';
import BookConfirmationModal from '../BookConfirmationModal';
import { useNavigate } from 'react-router-dom';

interface IRatesServiceability {
  bulkUploadData: IBulkUploadUrl | null;
  summaryData: ICheckAvailabilityResponse | null;
  setSummaryData: Dispatch<SetStateAction<ICheckAvailabilityResponse | null>>;
}
const RatesServiceability: FC<IRatesServiceability> = ({
  bulkUploadData,
  summaryData,
  setSummaryData
}) => {
  const [apiLoading, setApiLoading] = useState<boolean>(true);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [bookLoading, setBookLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const handleServiceability = async () => {
    await checkServiceability({
      setLoading: setApiLoading,
      setData: setSummaryData,
      bulkOrderId: bulkUploadData?.bulkOrderId || ''
    });
  };

  useEffect(() => {
    handleServiceability();
  }, []);

  const onCloseModal = () => {
    setConfirmModal(false);
  };

  const onSuccess = () => {
    navigate(`/b2b-bulk-order-details/${bulkUploadData?.bulkOrderId}`);
  };

  const onProceed = async () => {
    await bookBulkOrder({
      setLoading: setBookLoading,
      bulkOrderId: String(bulkUploadData?.bulkOrderId),
      callbackFun: onSuccess
    });
  };

  return (
    <>
      <div className={`px-3 py-4`}>
        {apiLoading ? (
          <Skeleton active={apiLoading} />
        ) : summaryData ? (
          <>
            <Button
              className="bg-blue-500 !text-white !border-blue-600 font-semibold flex gap-2 items-center mb-5"
              onClick={() => window.open(summaryData?.bulkServiceabilityReportUrl, '_blank')}>
              <FaDownload />
              Serviceability Report
            </Button>
            <div className="w-full md:max-w-[700px]">
              <div className="flex w-full flex-wrap gap-3 justify-between flex-col md:flex-row ">
                <div className="w-full md:w-[48%] flex flex-col gap-1 border border-gray-500 p-3 rounded-xl">
                  <div className="flex gap-2 justify-between items-end text-blue-500">
                    <p className="w-[230px] flex justify-between items-end">
                      Serviceable Orders <span>: </span>
                    </p>
                    <p className="font-semibold min-w-[50px] text-right">
                      {summaryData?.totalServiceableOrders}
                    </p>
                  </div>

                  <div className="flex gap-2 justify-between items-end text-red-500">
                    <p className="w-[230px] flex justify-between items-end">
                      Unserviceable Orders <span>: </span>
                    </p>
                    <p className="font-semibold min-w-[50px] text-right">
                      {summaryData?.totalunserviceableOrders}
                    </p>
                  </div>

                  <div className="flex gap-2 justify-between items-end border-t border-b border-gray-500 mt-2 py-1 font-semibold">
                    <p className="w-[230px] flex justify-between items-end">
                      Total Orders <span>: </span>
                    </p>
                    <p className="font-semibold min-w-[50px] text-right">
                      {summaryData?.totalOrders}
                    </p>
                  </div>
                </div>

                <div className="w-full md:w-[48%] flex flex-col gap-1 border border-gray-500 p-3 rounded-xl">
                  <div className="flex gap-2 justify-between items-end">
                    <p className="w-[230px] flex justify-between items-end">
                      Total Shipping Charges <span>: </span>
                    </p>
                    <p className="font-semibold min-w-[50px] text-right">
                      {summaryData?.totalShippingCharges}
                    </p>
                  </div>

                  <div className="flex gap-2 justify-between items-end">
                    <p className="w-[230px] flex justify-between items-end">
                      Total Collect On Delivery Charges <span>: </span>
                    </p>
                    <p className="font-semibold min-w-[50px] text-right">
                      {summaryData?.totalCollectOnDeliveryCharges}
                    </p>
                  </div>

                  {summaryData?.totalTaxes &&
                    Object.entries(summaryData?.totalTaxes)?.length > 0 && (
                      <>
                        <p className="w-[230px] flex justify-between items-end font-semibold mt-2">
                          Total Taxes
                        </p>
                        <div className="pl-5">
                          <>
                            {Object.entries(summaryData?.totalTaxes)?.map(([key, value]) => (
                              <div className="flex gap-2 justify-between items-end" key={key}>
                                <p className="w-[210px] flex justify-between capitalize">
                                  {key} <span>: </span>
                                </p>
                                <p className="font-semibold min-w-[50px] text-right">{value}</p>
                              </div>
                            ))}
                          </>
                        </div>
                      </>
                    )}

                  <div className="flex gap-2 justify-between items-end border-t border-b border-gray-500 mt-2 py-1 font-semibold">
                    <p className="w-[230px] flex justify-between items-end">
                      Net Total <span>: </span>
                    </p>
                    <p className="font-semibold min-w-[50px] text-right">{summaryData?.netTotal}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                {Number(summaryData?.totalServiceableOrders) === 0 && (
                  <p className="text-red-500">
                    <AlertFilled className="mr-3" />
                    There are no serviceable orders to book
                  </p>
                )}

                {Number(summaryData?.totalServiceableOrders) > 0 &&
                  Number(summaryData?.totalunserviceableOrders) > 0 && (
                    <p>
                      <AlertFilled className="mr-3" />
                      There are{' '}
                      <b className="text-blue-500">
                        {summaryData?.totalServiceableOrders} serviceable
                      </b>{' '}
                      and{' '}
                      <b className="text-red-500">
                        {summaryData?.totalunserviceableOrders} un-serviceable
                      </b>{' '}
                      orders.
                    </p>
                  )}
              </div>
              <div className="flex justify-center items-center mt-5 gap-3">
                <Button
                  type="primary"
                  onClick={() => setConfirmModal(true)}
                  className="disabled:bg-gray-500 disabled:cursor-not-allowed font-semibold"
                  disabled={Number(summaryData?.totalServiceableOrders) === 0}>
                  Book Orders
                </Button>
                <Tooltip title={'Check un-serviceable orders before booking orders.'}>
                  <FaInfoCircle />
                </Tooltip>
              </div>
            </div>
          </>
        ) : (
          <div className="flex gap-3 flex-col items-center">
            <p className="flex gap-2 text-red-500 items-center h-[50px] text-lg">
              <AlertOutlined /> Failed to fetch the serviceability details, please retry.
            </p>
            <Button
              icon={<BiRefresh />}
              className="primary-button flex items-center font-semibold"
              onClick={handleServiceability}>
              Retry
            </Button>
          </div>
        )}
      </div>

      <BookConfirmationModal
        confirmModal={confirmModal}
        onCancel={onCloseModal}
        onProceed={onProceed}
        modalLoading={bookLoading}
        serviceableOrders={Number(summaryData?.totalServiceableOrders)}
        unServiceableOrders={Number(summaryData?.totalunserviceableOrders)}
      />
    </>
  );
};

export default RatesServiceability;
