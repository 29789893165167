import Modal from 'antd/es/modal/Modal';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { PROVIDERS_DATA } from '../constants';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IProviderPreference } from '../../../../../interfaces/b2bBulkOrder';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';

interface IProviderPreferenceModal {
  isOpenModal: boolean;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
}
const ProviderPreferenceModal: FC<IProviderPreferenceModal> = ({ isOpenModal, setIsOpenModal }) => {
  const uniqueProviders = Array.from(new Set(PROVIDERS_DATA.map((provider) => provider.name)));

  const tableColumns: ColumnsType<IProviderPreference> = [
    {
      title: 'Provider',
      width: 150,
      dataIndex: 'name',
      fixed: 'left',
      filters: uniqueProviders.map((name) => ({
        text: name,
        value: name
      })),
      onFilter: (value, record) => record.name === value,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Logo',
      dataIndex: 'logo',
      width: 110,
      render: (logo, provider) => (
        <img className="max-w-[100px] max-h-[40px]" src={logo} alt={provider?.name} />
      )
    },
    {
      title: 'Carrier Code',
      dataIndex: 'carrierCode',
      width: 150,
      render: (code) => <GetCopyTextDiv text={code} />
    },
    {
      title: 'Pickup Cutoff Time',
      width: 100,
      dataIndex: 'pickupCutoffTime',
      render: (time) => <p className="tracking-wide text-blue-600 font-semibold">{time}</p>
    },
    {
      title: 'Service Name',
      dataIndex: 'services',
      key: 'name',
      width: 100,
      render: (services) => <GetCopyTextDiv text={services[0]?.name} />
    },
    {
      title: 'Service Code',
      dataIndex: 'services',
      key: 'serviceCode',
      width: 100,
      render: (services) => <GetCopyTextDiv text={services[0]?.serviceCode} />
    },
    {
      title: 'Modes Of Transport',
      dataIndex: 'services',
      key: 'modesOfTransport',
      width: 100,
      render: (services) => <Tag color="blue">{services[0]?.modesOfTransport[0]}</Tag>
    }
  ];

  return (
    <Modal
      width="90%"
      title={`Provider Preference ( ${PROVIDERS_DATA?.length} )`}
      onCancel={() => setIsOpenModal(false)}
      open={isOpenModal}
      footer={false}>
      <Table
        scroll={{ x: 820 }}
        className="mt-5"
        columns={tableColumns}
        dataSource={PROVIDERS_DATA}
        pagination={false}
      />
    </Modal>
  );
};

export default ProviderPreferenceModal;
