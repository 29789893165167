import React, { FC, useState } from 'react';
import { Button, Modal, Radio, RadioChangeEvent, Spin } from 'antd';

import { MdOpenInNew } from 'react-icons/md';
import Formlabel from '../../../CommonComponents/FormLabel';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import { generateReceipt } from '../api';
import { FaDownload } from 'react-icons/fa';

interface IGenerateReceiptButton {
  id: string;
  userId: string;
}
const GenerateReceiptButton: FC<IGenerateReceiptButton> = ({ id, userId }) => {
  const [generateModal, setGenerateModal] = useState<boolean>(false);
  const [isCustomerCopy, setIsCustomerCopy] = useState<boolean>(false);
  const [generateLoading, setGenerateLoading] = useState<boolean>(false);

  const onGenerateClick = () => {
    setGenerateModal(true);
  };

  const onSubmit = async () => {
    const payload = {
      id: id,
      userId: userId,
      customerCopy: isCustomerCopy
    };

    await generateReceipt({
      setLoading: setGenerateLoading,
      payload,
      callBackFunc: onClose
    });
  };

  const onClose = () => {
    setGenerateModal(false);
  };

  const handleCheckboxChange = (e: RadioChangeEvent) => {
    setIsCustomerCopy(e.target.checked);
  };

  return (
    <>
      <Button onClick={onGenerateClick} icon={<MdOpenInNew />}>
        Generate
      </Button>

      <Modal title="Generate Receipt" open={generateModal} onCancel={onClose} footer={false}>
        <Spin spinning={generateLoading}>
          <div className="flex gap-3 mt-5">
            <p className="w-[70px] flex gap-1 justify-between">
              ID <span>:</span>
            </p>
            <GetCopyTextDiv text={id} />
          </div>

          <div className="flex gap-3 my-3">
            <p className="w-[70px] flex gap-1 justify-between">
              User Id <span>:</span>
            </p>
            <GetCopyTextDiv text={userId} />
          </div>

          <div className="flex flex-col gap-1">
            <Formlabel labelText={<p className="font-semibold">Receipt Type :</p>} />
            <Radio.Group defaultValue={isCustomerCopy} onChange={handleCheckboxChange}>
              <Radio value={false}>DBP</Radio>
              <Radio value={true}>Customer Copy</Radio>
            </Radio.Group>
          </div>

          <div className="flex justify-end gap-2 mt-5">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              className="flex items-center"
              icon={<FaDownload />}
              onClick={onSubmit}
              type="primary">
              Download
            </Button>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default GenerateReceiptButton;
