import { Button } from 'antd';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { getUploadUrl, uploadBulkFile, validateData } from '../../api';
import {
  IBulkUploadUrl,
  IPickupAddressItem,
  IValidatedResponse
} from '../../../../../interfaces/b2bBulkOrder';
import { MdWarning } from 'react-icons/md';
import { WarningOutlined } from '@ant-design/icons';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';

interface IBulkUploadFile {
  disabledStep: boolean;
  priority: string;
  businessId: string;
  selectedAddress: IPickupAddressItem | null;
  rateCategory: string;
  isUploadSuccess: boolean;
  setIsUploadSuccess: Dispatch<SetStateAction<boolean>>;
  validatedResponse: IValidatedResponse | null;
  setValidatedResponse: Dispatch<SetStateAction<IValidatedResponse | null>>;
  nextStep: () => void;
  bulkUploadData: IBulkUploadUrl | null;
  setBulkUploadData: Dispatch<SetStateAction<IBulkUploadUrl | null>>;
  compKey: string;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  fileType: string | null;
  setFileType: Dispatch<SetStateAction<string | null>>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
  handleRemoveFile: () => void;
  validateLoading: boolean;
  setValidateLoading: Dispatch<SetStateAction<boolean>>;
}

const BulkUploadFile: FC<IBulkUploadFile> = ({
  disabledStep,
  priority,
  businessId,
  selectedAddress,
  rateCategory,
  isUploadSuccess,
  setIsUploadSuccess,
  validatedResponse,
  setValidatedResponse,
  nextStep,
  bulkUploadData,
  setBulkUploadData,
  compKey,
  fileInputRef,
  file,
  setFile,
  fileType,
  setFileType,
  error,
  setError,
  handleRemoveFile,
  validateLoading,
  setValidateLoading
}) => {
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  useEffect(() => {
    if (compKey && compKey !== '') {
      handleRemoveFile();
    }
  }, [compKey]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const extension = selectedFile.name.split('.').pop();
      if (extension === 'csv' || extension === 'xlsx') {
        setFileType(extension);
        setFile(selectedFile);
        setError(null);
      } else {
        setError('Invalid file format. Please upload a CSV or XLSX file.');
      }
    } else {
      setFile(null);
      setError('Invalid file format. Please upload a CSV or XLSX file.');
    }
  };

  const uploadFile = async ({ uploadUrl }: { uploadUrl: string }) => {
    if (uploadUrl && file) {
      const reader = new FileReader();
      reader.onload = async function (event) {
        if (event.target && event.target.result && fileType) {
          await uploadBulkFile({
            fileUrl: uploadUrl,
            payload: event.target.result,
            setLoading: setUploadLoading,
            setSuccess: setIsUploadSuccess,
            fileType: fileType
          });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const onClickUpload = async () => {
    setBulkUploadData(null);
    const response = await getUploadUrl({
      setLoading: setUploadLoading,
      fileFormat: fileType || '',
      setData: setBulkUploadData
    });

    if (response?.uploadURL) {
      await uploadFile({ uploadUrl: response?.uploadURL });
    }
  };

  const onClickValidate = async () => {
    if (selectedAddress?.id && bulkUploadData?.bulkOrderId) {
      await validateData({
        setLoading: setValidateLoading,
        fileFormat: fileType || '',
        carrierPriority: priority,
        addressId: selectedAddress?.id,
        businessId: businessId,
        bulkOrderId: bulkUploadData?.bulkOrderId,
        rateCategory: rateCategory,
        setData: setValidatedResponse,
        callbackFun: nextStep
      });
    }
  };

  return (
    <div className={`${disabledStep ? 'opacity-50 cursor-not-allowed select-none' : ''} px-3 pb-4`}>
      <Formlabel labelText="Upload xlsx/csv file: " required />
      <div className="mt-3 flex gap-5 items-center flex-wrap">
        <input
          type="file"
          accept=".csv, .xlsx"
          onChange={handleFileChange}
          ref={fileInputRef}
          className={`border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:cursor-not-allowed ${
            disabledStep && 'cursor-not-allowed'
          }`}
          disabled={disabledStep || !!file}
        />
        <div className="flex gap-4">
          <Button
            type={
              !file || error !== null || disabledStep || !!bulkUploadData?.uploadURL
                ? 'default'
                : 'primary'
            }
            disabled={!file || error !== null || disabledStep || !!bulkUploadData?.uploadURL}
            onClick={onClickUpload}
            loading={uploadLoading}>
            {bulkUploadData?.uploadURL ? 'Uploaded' : 'Upload'}
          </Button>
          {file && (
            <Button
              disabled={
                validateLoading ||
                uploadLoading ||
                disabledStep ||
                !!(validatedResponse && validatedResponse?.noValidationErrors)
              }
              type="default"
              onClick={handleRemoveFile}>
              Remove
            </Button>
          )}
        </div>
      </div>

      {bulkUploadData?.bulkOrderId && (
        <div className="mt-5 flex flex-wrap gap-2 md:text-xl">
          <p>Bulk Order ID :</p>
          <GetCopyTextDiv text={bulkUploadData?.bulkOrderId} />
        </div>
      )}

      {validatedResponse && !validatedResponse?.noValidationErrors && (
        <p className="text-red-500 mt-4 flex items-center gap-1">
          <WarningOutlined />
          Validation failed, please check the <b>Error Report</b> and retry.
        </p>
      )}

      {isUploadSuccess && (
        <div className="mt-5 flex gap-4 flex-wrap">
          <Button
            loading={validateLoading}
            onClick={onClickValidate}
            type={validatedResponse ? 'default' : 'primary'}
            className="disabled:bg-none"
            disabled={!!validatedResponse}>
            Validate
          </Button>

          {validatedResponse?.bulkErrorReportUrl && (
            <Button
              className="bg-red-500 !text-white !border-red-600 font-semibold flex gap-2 items-center"
              onClick={() => window.open(validatedResponse?.bulkErrorReportUrl, '_blank')}>
              <MdWarning />
              Download Error Report
            </Button>
          )}
        </div>
      )}

      {error && <p className="text-red-500 mt-4">{error}</p>}
    </div>
  );
};

export default BulkUploadFile;
