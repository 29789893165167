import { Button, Form, PaginationProps, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { getB2BOrders } from '../api';
import { ColumnsType } from 'antd/es/table';
import { Typography } from 'antd';
import {
  IB2BOrderAddress,
  IB2bV3OrdersData,
  IB2bV3OrdersList
} from '../../../../interfaces/b2bV3OrdersList';
import { GetProviderLogo } from '../../../../utils/GetProviderLogos';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import GetOrderByIdFilter from './utils/GetOrderById';
import DateRangeFilter from './utils/DateRangeFilter';
import GetOrderByStatus from './utils/GetOrderByStatus';
import { getProviders } from '../../../../hooks/b2bV3Providers';
import { IProvidersListResponse } from '../../../../interfaces/b2bVerification';
import { SearchOutlined } from '@ant-design/icons';
import GetOrderByCarrier from './utils/GetOrderByCarrier';
import { Link } from 'react-router-dom';

const B2BOrdersList = () => {
  const [orderIdSearchForm] = Form.useForm();
  const [trackingIdSearchForm] = Form.useForm();
  const [clientReferenceIdSearchForm] = Form.useForm();
  const [businessOrderIdForm] = Form.useForm();
  const [orderStatusForm] = Form.useForm();
  const [filterCarrierForm] = Form.useForm();

  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: '',
    to: ''
  });
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [orderData, setOrdersData] = useState<IB2bV3OrdersList>();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [orderId, setOrderId] = useState<string>('');
  const [businessOrderId, setBusinessOrderId] = useState<string>('');
  const [trackingId, setTrackingId] = useState<string>('');
  const [clientReferenceId, setClientReferenceId] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [providersLoading, setProvidersLoading] = useState<boolean>(false);
  const [providersData, setProvidersData] = useState<IProvidersListResponse[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<string>('');

  const handleGetB2BOrders = async () => {
    await getB2BOrders({
      setLoading: setIsTableLoading,
      setData: setOrdersData,
      pageLimit: pageLimit,
      skip: currentPage,
      orderId: orderId,
      trackingId: trackingId,
      clientReferenceId: clientReferenceId,
      businessOrderId: businessOrderId,
      from: dateRange?.from,
      to: dateRange?.to,
      orderStatus: orderStatus,
      selectedProvider: selectedProvider
    });
  };
  useEffect(() => {
    handleGetB2BOrders();
  }, [
    pageLimit,
    currentPage,
    orderId,
    trackingId,
    clientReferenceId,
    businessOrderId,
    dateRange,
    orderStatus,
    selectedProvider
  ]);

  useEffect(() => {
    getProviders({ setLoading: setProvidersLoading, setData: setProvidersData });
  }, []);

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const tableColumns: ColumnsType<IB2bV3OrdersData> = [
    {
      dataIndex: 'id',
      width: 200,
      fixed: 'left',
      render: (id) =>
        id ? (
          <div className="flex items-center gap-1 flex-wrap">
            <Link to={`/b2b-order-details/${id}`} target="_blank">
              <p className={`m-0 text-blue-500 hover:underline`}>
                <b>{id}</b>
              </p>
            </Link>
            <Typography.Text copyable={{ text: id }} />
          </div>
        ) : (
          <p>NA</p>
        ),
      ...GetOrderByIdFilter({
        orderId: orderId,
        setOrderId: setOrderId,
        formInstance: orderIdSearchForm,
        formId: 'orderIdSearchForm',
        placeHolder: 'Order ID',
        title: 'Order ID'
      })
    },
    {
      dataIndex: 'carriers',
      key: 'trackingNumber',
      width: 180,
      render: (text) => <GetCopyTextDiv text={text[0]?.trackingNumber} />,
      ...GetOrderByIdFilter({
        orderId: trackingId,
        setOrderId: setTrackingId,
        formInstance: trackingIdSearchForm,
        placeHolder: 'Tracking Number',
        formId: 'trackingIdSearchForm',
        title: 'Tracking Number'
      })
    },
    {
      dataIndex: 'carriers',
      key: 'carrier',
      render: (text) => (
        <img
          src={GetProviderLogo(text[0]?.carrier || '')}
          alt="provider"
          style={window.innerWidth > 699 ? { width: '6rem' } : { width: '3rem' }}
        />
      ),
      width: 150,
      ...GetOrderByCarrier({
        filterCarrierForm: filterCarrierForm,
        selectedProvider: selectedProvider,
        setSelectedProvider: setSelectedProvider,
        providersData: providersData
      })
    },
    {
      title: 'Service',
      dataIndex: 'carriers',
      key: 'service',
      width: 100,
      render: (text) => <Tag>{text[0]?.service || '--'}</Tag>,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      )
    },
    {
      title: 'Source',
      dataIndex: 'addresses',
      key: 'source',
      width: 200,
      render: (address) => {
        const currentAddress =
          address?.filter((item: IB2BOrderAddress) => item?.savedAs === 'SOURCE') || [];

        return currentAddress?.length ? (
          <p>
            {`${currentAddress[0]?.city}, ${currentAddress[0]?.state} - ${currentAddress[0]?.postalCode}`}{' '}
          </p>
        ) : (
          <p>NA</p>
        );
      }
    },
    {
      title: 'Destination',
      dataIndex: 'addresses',
      key: 'destination',
      width: 150,
      render: (address) => {
        const currentAddress =
          address?.filter((item: IB2BOrderAddress) => item?.savedAs === 'DESTINATION') || [];
        return currentAddress?.length ? (
          <p>
            {`${currentAddress[0]?.city}, ${currentAddress[0]?.state} - ${currentAddress[0]?.postalCode}`}{' '}
          </p>
        ) : (
          <p>NA</p>
        );
      }
    },
    {
      title: 'Return',
      dataIndex: 'addresses',
      key: 'return',
      width: 150,
      render: (address) => {
        const currentAddress =
          address?.filter((item: IB2BOrderAddress) => item?.savedAs === 'RETURN') || [];
        return currentAddress?.length ? (
          <p>
            {`${currentAddress[0]?.city}, ${currentAddress[0]?.state} - ${currentAddress[0]?.postalCode}`}{' '}
          </p>
        ) : (
          <p>NA</p>
        );
      }
    },
    {
      dataIndex: 'businessId',
      width: 180,
      render: (id, item) => (
        <div>
          <p className="font-semibold">{item?.business?.brandName}</p>
          <GetCopyTextDiv text={id} />
        </div>
      ),
      ...GetOrderByIdFilter({
        orderId: businessOrderId,
        setOrderId: setBusinessOrderId,
        formInstance: businessOrderIdForm,
        formId: 'businessOrderIdForm',
        placeHolder: 'Business ID',
        title: 'Business ID'
      })
    },
    {
      title: 'Bulk Order ID',
      dataIndex: 'bulkOrderId',
      width: 180,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      dataIndex: 'clientOrderReferenceId',
      width: 180,
      render: (id) => <GetCopyTextDiv text={id} />,
      ...GetOrderByIdFilter({
        orderId: clientReferenceId,
        setOrderId: setClientReferenceId,
        formInstance: clientReferenceIdSearchForm,
        placeHolder: 'Client Order ReferenceId',
        formId: 'clientReferenceIdSearchForm',
        title: 'Client Order ReferenceId'
      })
    },
    {
      dataIndex: 'createdAt',
      width: 120,
      ...DateRangeFilter({ setDateRange: setDateRange, dateRange: dateRange })
    },
    {
      dataIndex: 'status',
      width: 150,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      render: (value) => (value ? <Tag>{value?.split('_')?.join(' ')}</Tag> : '--'),
      ...GetOrderByStatus({
        orderStatusForm: orderStatusForm,
        setOrderStatus: setOrderStatus,
        orderStatus: orderStatus
      })
    }
  ];

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">B2B Orders List</h2>
        <Button className="m-0" onClick={handleGetB2BOrders}>
          Refresh
        </Button>
      </div>

      <Table
        columns={tableColumns}
        loading={isTableLoading}
        rowKey={'id'}
        scroll={{ x: 1600 }}
        dataSource={orderData?.rows}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: orderData?.count ? Math.ceil(orderData?.count / pageLimit) * pageLimit : 0,
          onChange: onPageChange
        }}
      />
    </>
  );
};

export default B2BOrdersList;
