import { Button, Form, Input } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../CommonComponents/FormLabel';
import { EMAIL_REGEX } from '../../../../utils/constant';
import { getEcboBatches } from '../api';
import { IFranchisee } from '../../../../interfaces/ecboBulkOrder';

interface IEmailVerificationForm {
  setBatchesData: Dispatch<SetStateAction<IFranchisee[] | null>>;
  setEnteredEmailId: Dispatch<SetStateAction<string>>;
}

const EmailVerificationForm: FC<IEmailVerificationForm> = ({
  setBatchesData,
  setEnteredEmailId
}) => {
  const [emailForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSuccess = (email: string) => {
    setEnteredEmailId(email);
  };
  const handleSubmitEmail = async (value: { email: string }) => {
    setBatchesData(null);
    setEnteredEmailId('');
    await getEcboBatches({
      setLoading: setIsLoading,
      email: value?.email,
      setData: setBatchesData,
      callBackFunc: onSuccess
    });
  };

  return (
    <div className="flex justify-center">
      <div className="flex gap-3 items-end mb-4 primary-box-shadow p-4 rounded">
        <Form
          form={emailForm}
          layout="vertical"
          id="shipmentLabelForm"
          onFinish={handleSubmitEmail}>
          <div className="flex gap-3 items-center">
            <Formlabel labelText="Enter Email :" />
          </div>
          <div className="flex gap-2 mt-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter email id.'
                },
                {
                  pattern: EMAIL_REGEX?.pattern,
                  message: EMAIL_REGEX?.message
                }
              ]}
              name="email"
              className="mb-0">
              <Input placeholder="Email Id" />
            </Form.Item>
            <Button
              loading={isLoading}
              className="primary-button outline-none border-none"
              htmlType="submit"
              form="shipmentLabelForm">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default EmailVerificationForm;
