export const formValidations = {
  name: {
    requiredMessage: 'Please enter name',
    regexPattern: /^.{0,50}$/,
    regexMessage: 'Only 50 Characters Allowed'
  },
  companyName: {
    requiredMessage: 'Please enter customer name',
    regexPattern: /^.{0,200}$/,
    regexMessage: 'Only 200 Characters Allowed'
  },
  address: {
    requiredMessage: 'Please Enter Address',
    regexPattern: /^[^@_!#$%^&*()<>?|}{~:]*$/,
    regexMessage: 'Special characters not allowed',
    maxCharacterPattern: /^.{0,100}$/,
    maxCharacterMessage: 'Max 100 characters allowed.'
  },
  isdCode: {
    requiredMessage: 'Please Enter ISD code',
    regexPattern: /^\+?\d{1,3}$/,
    regexMessage: 'Invalid ISD code'
  },
  email: {
    requiredMessage: 'Please enter email',
    regexPattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
    regexMessage: 'Invalid email id.'
  },
  mobileNo: {
    requiredMessage: 'Please enter phone number',
    regexPattern: /^[6-9]\d{9}$/,
    regexMessage: 'Please enter valid phone number.'
  },
  postalCode: {
    requiredMessage: 'Please enter postal code',
    regexPattern: /^\d{1,6}$/,
    regexMessage: 'Please enter valid postal code.'
  },
  country: {
    requiredMessage: 'Please enter country name.'
  },
  state: {
    requiredMessage: 'Please enter state name.'
  },
  city: {
    requiredMessage: 'Please enter city name.'
  },
  tag: {
    requiredMessage: 'Please enter any tag name.',
    regexPattern: /^.{0,40}$/,
    regexMessage: 'Max 40 characters allowed'
  }
};
