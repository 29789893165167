import React, { useState } from 'react';
import EmailVerificationForm from './EmailVerificationForm';
import { IFranchisee } from '../../../interfaces/ecboBulkOrder';
import BatchesTable from './BatchesTable';
import { Empty } from 'antd';
import GetCopyTextDiv from '../../CommonComponents/GetCopyTextDiv';

const ECBOBatches = () => {
  const [batchesData, setBatchesData] = useState<IFranchisee[] | null>(null);
  const [enteredEmailId, setEnteredEmailId] = useState<string>('');

  return (
    <div>
      <h2 className="text-xl font-normal m-0 mb-4">ECBO Batches</h2>

      <EmailVerificationForm
        setBatchesData={setBatchesData}
        setEnteredEmailId={setEnteredEmailId}
      />

      {enteredEmailId && (
        <div>
          <p className="text-xl flex gap-3">
            <span>Email:</span>
            <span className="underline underline-offset-4">
              <GetCopyTextDiv text={enteredEmailId} />
            </span>
          </p>
          {batchesData ? (
            <BatchesTable batchesData={batchesData} />
          ) : (
            <Empty
              className="mt-5"
              description={
                <p className="text-xl">
                  To data found for <b>{enteredEmailId}</b>
                </p>
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ECBOBatches;
