import { Modal, Tag } from 'antd';
import React, { FC } from 'react';
import DownloadDocketsButton from '../DownloadDocketsButton';

interface IDocketsModal {
  title?: string;
  isModalOpen: boolean;
  docketsData: string[];
  onClose: () => void;
}
const DocketsModal: FC<IDocketsModal> = ({
  title = 'Dockets',
  isModalOpen,
  docketsData,
  onClose
}) => {
  return (
    <Modal open={isModalOpen} onCancel={onClose} title={title} footer={false}>
      <DownloadDocketsButton title={title} docketsData={docketsData} />
      <div className="flex flex-wrap gap-2 mt-5">
        {docketsData?.map((id) => (
          <Tag key={id}>{id}</Tag>
        ))}
      </div>
    </Modal>
  );
};

export default DocketsModal;
