import React, { FC, useState } from 'react';
import { IDocketSeries, IFranchisee } from '../../../../interfaces/ecboBulkOrder';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';
import DocketsModal from '../DocketsModal';
import { MdOpenInNew } from 'react-icons/md';
import GenerateReceiptButton from '../GenerateReceiptButton';
import DownloadDocketsButton from '../DownloadDocketsButton';

interface IBatchesTable {
  batchesData: IFranchisee[] | null;
}

const BatchesTable: FC<IBatchesTable> = ({ batchesData }) => {
  const [docketModalData, setDocketModalData] = useState<{
    isOpen: boolean;
    title: string;
    data: string[];
  }>({
    isOpen: false,
    title: '',
    data: []
  });

  const tableColumns: ColumnsType<IFranchisee> = [
    {
      title: 'ID',
      dataIndex: '_id',
      width: 210,
      fixed: 'left',
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'User Id',
      dataIndex: 'userId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Franchisee Id',
      dataIndex: 'franchiseeId',
      width: 150,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Partner Code',
      dataIndex: 'partnerCode',
      width: 150,
      render: (code) => <GetCopyTextDiv text={code} />
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (text: string) => <span>{moment(text).format('YYYY-MM-DD, HH:mm:ss')}</span>
    },
    {
      title: 'Franchisee Name',
      dataIndex: 'franchiseeName',
      width: 150
    },
    {
      title: <div className="text-center">Franchisee Mobile Number</div>,
      dataIndex: 'franchiseeMobileNo',
      width: 150,
      render: (num) => <GetCopyTextDiv text={num} />
    },
    {
      title: <div className="text-center">Franchisee Name</div>,
      dataIndex: 'franchiseeAddress',
      width: 150
    },
    {
      title: <div className="text-center">Franchisee Postal Code</div>,
      dataIndex: 'franchiseeAddressPostalCode',
      width: 120
    },
    {
      title: 'Used Dockets',
      dataIndex: 'usedDockets',
      width: 100,
      render: (dockets) => {
        const title = `Used Dockets (${dockets.length})`;
        return (
          <div className="flex items-center gap-2">
            <p className="m-0">{dockets?.length}</p>

            {!!dockets?.length && (
              <div className="flex items-center gap-2">
                <Tooltip title="View Dockets">
                  <Button
                    icon={<MdOpenInNew />}
                    onClick={() => handleDocketsModal({ dockets: dockets, title })}
                  />
                </Tooltip>

                <DownloadDocketsButton onlyIcon title={title} docketsData={dockets} />
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: `Unused Dockets`,
      dataIndex: 'unusedDockets',
      width: 120,
      render: (dockets) => {
        const title = `Unused Dockets ( ${dockets?.length} )`;
        return (
          <div className="flex items-center gap-2">
            <p className="m-0">{dockets?.length}</p>

            {!!dockets?.length && (
              <div className="flex items-center gap-2">
                <Tooltip title="View Dockets">
                  <Button
                    icon={<MdOpenInNew />}
                    onClick={() => handleDocketsModal({ dockets: dockets, title: title })}
                  />
                </Tooltip>

                <DownloadDocketsButton onlyIcon title={title} docketsData={dockets} />
              </div>
            )}
          </div>
        );
      }
    }
  ];

  const handleDocketsModal = ({ dockets, title }: { dockets: string[]; title: string }) => {
    setDocketModalData({ isOpen: true, title: title, data: dockets });
  };

  const onCloseModal = () => {
    setDocketModalData((prev) => ({ ...prev, isOpen: false }));

    setTimeout(() => {
      setDocketModalData((prev) => ({ ...prev, title: '', data: [] }));
    }, 200);
  };

  const docketSeriesTableColumns: ColumnsType<IDocketSeries> = [
    {
      title: 'ID',
      dataIndex: '_id',
      width: 210,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuedAt',
      width: 200,
      render: (text: string) => <span>{moment(text).format('YYYY-MM-DD, HH:mm:ss')}</span>
    },
    {
      title: `Dockets`,
      dataIndex: 'dockets',
      width: 220,
      render: (dockets) => {
        const title = `Dockets ( ${dockets?.length} )`;
        return (
          <div className="flex items-center gap-2">
            <p className="m-0">{dockets?.length}</p>

            {!!dockets?.length && (
              <div className="flex items-center gap-2">
                <Tooltip title="View Dockets">
                  <Button
                    icon={<MdOpenInNew />}
                    onClick={() => handleDocketsModal({ dockets: dockets, title: title })}
                  />
                </Tooltip>

                <DownloadDocketsButton onlyIcon title={title} docketsData={dockets} />
              </div>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div className="mt-5">
        <Table
          scroll={{ x: 2100 }}
          columns={tableColumns}
          rowKey="_id"
          dataSource={batchesData || []}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div className="bg-white">
                  <p className="text-xl mb-5 ml-5">Docket Series - {`( ${record?._id} )`}</p>
                  <Table
                    columns={[
                      ...docketSeriesTableColumns,
                      {
                        title: 'Receipt',
                        key: 'receipt',
                        dataIndex: '_id',
                        width: 120,
                        fixed: 'right',
                        render: (id) => <GenerateReceiptButton id={id} userId={record?.userId} />
                      }
                    ]}
                    dataSource={record?.docketSeries}
                    pagination={false}
                    rowKey="_id"
                  />
                </div>
              );
            },
            rowExpandable: (record) => record?.docketSeries && record?.docketSeries?.length > 0
          }}
          pagination={false}
        />
      </div>

      <DocketsModal
        title={docketModalData?.title}
        isModalOpen={docketModalData?.isOpen}
        docketsData={docketModalData?.data}
        onClose={onCloseModal}
      />
    </>
  );
};

export default BatchesTable;
