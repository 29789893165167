import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../../../utils/apiRequest';
import { ErrorMessage, SuccessMessage } from '../../../../../utils/ResponseMessages';
import {
  IAddressBookResponse,
  IAddressFormFields,
  ICountry,
  IEditAddressFormFields,
  IStateList
} from '../../../../../interfaces/b2bAddressBook';

export const getAddressList = async ({
  setLoading,
  setData,
  businessId,
  currentPage,
  pageLimit,
  postalCode
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setData: Dispatch<SetStateAction<IAddressBookResponse>>;
  businessId: string;
  postalCode?: string;
  pageLimit: number;
  currentPage: number;
}) => {
  setLoading(true);
  const offSet = pageLimit * (currentPage - 1);
  const queryParams = `?offset=${offSet}&limit=${pageLimit}`;
  const pincode = postalCode ? `&postalCode=${postalCode}` : '';

  try {
    const response = await httpClient.get(
      `/businesses/${businessId}/addresses${queryParams}${pincode}`
    );
    setData({ addresses: response?.data?.data?.addresses, count: response?.data?.data?.count });
  } catch (error: any) {
    setData({ addresses: [], count: 0 });
    ErrorMessage(error?.response?.data?.message);
    console.error('Error in fetching Address List:', error);
  } finally {
    setLoading(false);
  }
};

export const getCountriesList = async ({
  setData
}: {
  setData: Dispatch<SetStateAction<ICountry[] | []>>;
}) => {
  try {
    const response = await httpClient.get(`/businesses/places/countries`);
    setData(response?.data?.data?.countries);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching Country Details', error);
  }
};

export const getStatesList = async ({
  setData,
  countryCode,
  setLoading
}: {
  setData: Dispatch<SetStateAction<IStateList[] | []>>;
  countryCode: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  setLoading(true);

  try {
    const response = await httpClient.get(`/businesses/places/countries/${countryCode}/states`);
    setData(response?.data?.data?.states);
  } catch (error: any) {
    setData([]);
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching State Details', error);
  } finally {
    setLoading(false);
  }
};

export const createNewAddress = async ({
  setLoading,
  businessId,
  payload,
  callBackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  payload: IAddressFormFields;
  callBackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.post(`/businesses/${businessId}/addresses`, payload);
    SuccessMessage('Created Successfully');
    if (callBackFun) callBackFun();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error fetching creating new address', error);
  } finally {
    setLoading(false);
  }
};

export const deleteAddressAPI = async ({
  setLoading,
  businessId,
  addressId,
  callBackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  addressId: string;
  callBackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.delete(`/businesses/${businessId}/addresses/${addressId}`);
    SuccessMessage('Deleted Successfully');
    if (callBackFun) callBackFun();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error deleting address', error);
  } finally {
    setLoading(false);
  }
};

export const editAddressAPI = async ({
  setLoading,
  businessId,
  addressId,
  payload,
  callBackFun
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  businessId: string;
  addressId: string;
  payload: IEditAddressFormFields;
  callBackFun?: () => void;
}) => {
  setLoading(true);
  try {
    await httpClient.put(`/businesses/${businessId}/addresses/${addressId}`, payload);
    SuccessMessage('Updated Successfully');
    if (callBackFun) callBackFun();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
    console.error('Error Updating address', error);
  } finally {
    setLoading(false);
  }
};
