import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Form, FormInstance, Spin, Table } from 'antd';
import { BulkOrderColumns } from './Partials/BulkOrderColumns';
import { checkValidation } from './Partials/BulkOrderUtils';
import { createBulkOrder, generateBulkOrderId } from '../../api';
import { IECBOEmailVerifiedData } from '../../../../../interfaces/ecboBulkOrder';

interface BulkOrdersTableProps {
  ordersFile: { [key: string]: any }[];
  setOrdersFile: Dispatch<
    SetStateAction<
      {
        [key: string]: any;
      }[]
    >
  >;
  ordersValidated: boolean;
  setOrdersValidated: Dispatch<SetStateAction<boolean>>;
  setErrorMsg: Dispatch<SetStateAction<string>>;
  ordersCount: number;
  setOrdersCount: Dispatch<SetStateAction<number>>;
  maxOrdersAllowed: number;
  ordersResponseData: {
    [key: string]: any;
  }[];
  setOrdersResponseData: Dispatch<
    SetStateAction<
      {
        [key: string]: any;
      }[]
    >
  >;
  tableLoading: boolean;
  setTableLoading: Dispatch<SetStateAction<boolean>>;
  setBulkId: Dispatch<SetStateAction<string>>;
  bulkLoading: boolean;
  setBulkLoading: Dispatch<SetStateAction<boolean>>;
  failedOrderLoading: boolean;
  // eslint-disable-next-line
  customBulkOrderForm: FormInstance<any>;
  emailVerifiedData: IECBOEmailVerifiedData | undefined;
}

const BulkOrdersTable: React.FC<BulkOrdersTableProps> = ({
  ordersFile,
  setOrdersFile,
  setOrdersValidated,
  setErrorMsg,
  ordersCount,
  setOrdersCount,
  maxOrdersAllowed,
  ordersResponseData,
  setOrdersResponseData,
  tableLoading,
  setTableLoading,
  setBulkId,
  bulkLoading,
  setBulkLoading,
  failedOrderLoading,
  customBulkOrderForm,
  emailVerifiedData
}) => {
  useEffect(() => {
    // code to show a error message if there is any invalid orders
    const invalidOrders = ordersFile?.map((order) => checkValidation(order));
    if (invalidOrders?.filter((item) => item?.invalidOrder?.length)?.length > 0) {
      setErrorMsg('Please check the errors in the table.');
      setOrdersValidated(false);
    } else {
      setOrdersValidated(true);
      setErrorMsg('');
    }
    if (!ordersFile?.length) {
      setErrorMsg('');
    }
  }, [ordersFile]);

  useEffect(() => {
    // code to append the response message to orders file
    if (ordersResponseData?.length === ordersFile?.length) {
      setOrdersFile(ordersResponseData);
      setOrdersCount(0);
    }
  }, [ordersResponseData]);

  const onUploadOrders = async () => {
    const response = await generateBulkOrderId({ setLoading: setBulkLoading });

    if (response?.status >= 200 && response?.status <= 300) {
      setBulkId(response?.data);
      const payload = ordersFile?.map((order) => {
        const orderPayload = {
          batch_id: response?.data,
          user_id: emailVerifiedData?.userId,
          order_id: order?.order_id,
          delivery_address_line1: order?.delivery_address_line1,
          delivery_address_line2: order?.delivery_address_line2,
          delivery_email: order?.delivery_email,
          delivery_customer_name: order?.delivery_customer_name,
          delivery_address_country: order?.delivery_address_country,
          delivery_address_pincode: order?.delivery_address_pincode?.toString(),
          delivery_address_state: order?.delivery_address_state,
          delivery_address_city: order?.delivery_address_city,
          delivery_phone_number: order?.delivery_phone_number?.toString(),
          billing_customer_name: order?.billing_customer_name,
          billing_address_line1: order?.billing_address_line1,
          billing_address_line2: order?.billing_address_line2,
          billing_address_city: order?.billing_address_city,
          billing_address_pincode: order?.billing_address_pincode?.toString(),
          billing_address_state: order?.billing_address_state,
          billing_address_country: order?.billing_address_country,
          billing_phone_number: order?.billing_phone_number?.toString(),
          billing_email: order?.billing_email,
          shipment_length: order?.shipment_length,
          shipment_width: order?.shipment_width,
          shipment_height: order?.shipment_height,
          weight: order?.weight?.toString(),
          item_description: order?.item_description,
          estimated_value: order?.estimated_value?.toString(),
          service_type: order?.service_type,
          delivery_vendor: order?.delivery_vendor,
          item_category: order?.item_category?.toString(),
          eway_bill: order?.eway_bill || null,
          expiry_date: order?.expiry_date || null,
          seller_gst_no: order?.seller_gst_no?.toString() || null,
          buyer_gst_no: order?.buyer_gst_no?.toString() || null,
          product_invoice_no: order?.product_invoice_no?.toString() || null,
          product_invoice_date: order?.product_invoice_date || null,
          taxable_value: order?.taxable_value || null,
          cgst_amount: order?.cgst_amount || null,
          sgst_amount: order?.sgst_amount || null,
          igst_amount: order?.igst_amount || null
        };

        return orderPayload;
      });
      setOrdersResponseData([]);
      setTableLoading(true);

      payload?.map(async (order, index) => {
        setTimeout(async () => {
          const { data }: any = await createBulkOrder(order);
          setOrdersResponseData((prev) => [...prev, { ...data?.data, ...order }]);
          if (payload?.length === index + 1) {
            setTableLoading(false);
          }
        }, 400 * index);
      });
    }
  };

  return (
    <div className="p-2">
      {ordersCount && ordersCount > maxOrdersAllowed ? (
        <p className="mb-1 text-red-600">*Showing first {maxOrdersAllowed} orders only.</p>
      ) : null}
      {ordersFile?.length ? (
        <Form
          form={customBulkOrderForm}
          scrollToFirstError
          id={'orderForm'}
          layout="vertical"
          onFinish={onUploadOrders}>
          <Table
            columns={BulkOrderColumns({
              bookedAllItems: ordersResponseData?.length === ordersFile?.length
            })}
            loading={{
              spinning: tableLoading || bulkLoading || failedOrderLoading,
              indicator: (
                <div
                  style={{
                    width: 'fit-content',
                    transform: 'translateX(-50%)'
                  }}>
                  <Spin />
                  {!!failedOrderLoading && (
                    <p className="mb-0">Please wait, pushing failed orders...</p>
                  )}
                  {!!bulkLoading && <p className="mb-0">Getting orders ready...</p>}
                  {!!tableLoading && <p className="mb-0">Please wait, booking orders...</p>}
                </div>
              )
            }}
            dataSource={ordersFile}
            bordered
            rowKey={'id'}
            scroll={{
              x: 5000
            }}
            pagination={false}
            sticky
            expandable={{
              defaultExpandAllRows: true,
              showExpandColumn: false,
              expandedRowRender: (record) => {
                const { invalidOrder } = checkValidation(record);
                return (
                  <ul>
                    {invalidOrder?.map((err, index) => (
                      <li key={`${index}`} className="text-red-600">
                        {err}
                      </li>
                    ))}
                  </ul>
                );
              },
              rowExpandable: (record) =>
                checkValidation(record)?.invalidOrder?.length ? true : false
            }}
          />
        </Form>
      ) : null}
    </div>
  );
};

export default BulkOrdersTable;
