import { AlertFilled } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';

interface IBookConfirmationModal {
  confirmModal: boolean;
  onCancel: () => void;
  serviceableOrders: number;
  unServiceableOrders: number;
  onProceed: () => void;
  modalLoading: boolean;
}
const BookConfirmationModal: FC<IBookConfirmationModal> = ({
  confirmModal,
  onCancel,
  serviceableOrders,
  unServiceableOrders,
  onProceed,
  modalLoading
}) => {
  return (
    <Modal title="Confirm to book !" open={confirmModal} onCancel={onCancel} footer={false}>
      <Spin spinning={modalLoading}>
        <div className="min-h-[100px] py-3 text-lg">
          {Number(serviceableOrders) > 0 && Number(unServiceableOrders) > 0 && (
            <>
              <p className="text-lg">
                There are <b className="text-blue-500">{serviceableOrders} serviceable</b> and{' '}
                <b className="text-red-500">{unServiceableOrders} un-serviceable</b> orders.
              </p>
              <p className="text-lg">
                Proceed to book <b className="text-blue-500">{serviceableOrders} serviceable</b>{' '}
                only?
              </p>
            </>
          )}

          {Number(serviceableOrders) === 0 && (
            <>
              <p className="text-red-500">
                <AlertFilled className="mr-3" />
                There are no serviceable orders to book
              </p>
            </>
          )}

          {Number(serviceableOrders) > 0 && Number(unServiceableOrders) === 0 && (
            <p className="text-lg">
              Proceed to book <b>{serviceableOrders}</b> orders?
            </p>
          )}
        </div>

        {Number(serviceableOrders) > 0 && (
          <div className="flex justify-end gap-2 mt-5">
            <Button className="font-semibold" type="primary" onClick={onProceed}>
              Proceed
            </Button>
            <Button className="font-semibold" onClick={onCancel}>
              Close
            </Button>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default BookConfirmationModal;
