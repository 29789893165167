import { Button, PaginationProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { getB2BBulkOrdersList } from '../api';
import { IBulkOrderData, IBulkOrdersListResponse } from '../../../../interfaces/b2bBulkOrder';
import Table, { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';
import GetStatus from '../../../../utils/GetStatus';

const B2BBulkOrdersList = () => {
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IBulkOrdersListResponse>({
    bulkOrders: [],
    count: 0
  });
  const [pageLimit, setPageLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const handleGetBulkOrders = async () => {
    await getB2BBulkOrdersList({
      setLoading: setIsTableLoading,
      setData: setTableData,
      pageLimit: pageLimit,
      skip: currentPage
    });
  };

  useEffect(() => {
    handleGetBulkOrders();
  }, [pageLimit, currentPage]);

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const tableColumns: ColumnsType<IBulkOrderData> = [
    {
      title: 'Bulk ID',
      dataIndex: 'id',
      width: 200,
      fixed: 'left',
      render: (id) =>
        id ? (
          <div className="flex items-center gap-1 flex-wrap">
            <Link to={`/b2b-bulk-order-details/${id}`} target="_blank">
              <p className={`m-0 text-blue-500 hover:underline`}>
                <b>{id}</b>
              </p>
            </Link>
            <Typography.Text copyable={{ text: id }} />
          </div>
        ) : (
          <p>NA</p>
        )
    },
    {
      title: 'Business ID',
      dataIndex: 'businessId',
      width: 180,
      render: (businessId) => <GetCopyTextDiv text={businessId} />
    },
    {
      title: 'User Id',
      dataIndex: 'userId',
      width: 180,
      render: (userId) => <GetCopyTextDiv text={userId} />
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 120,
      render: (text: string) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
    },
    {
      title: (
        <div className="text-center">
          Serviceable <br />
          Orders
        </div>
      ),
      dataIndex: 'serviceableOrders',
      className: 'text-center',
      width: 120,
      render: (data) => <p>{data}</p>
    },
    {
      title: (
        <div className="text-center">
          Unserviceable <br />
          Orders
        </div>
      ),
      className: 'text-center',
      dataIndex: 'unserviceableOrders',
      width: 120
    },
    {
      title: (
        <div className="text-center">
          Successful <br />
          Orders
        </div>
      ),
      dataIndex: 'successfulOrders',
      className: 'text-center',
      width: 120
    },
    {
      title: (
        <div className="text-center">
          Failed <br />
          Orders
        </div>
      ),
      className: 'text-center',
      dataIndex: 'failedOrders',
      width: 120
    },
    {
      title: (
        <div className="text-center">
          Total <br />
          Orders
        </div>
      ),
      className: 'text-center',
      dataIndex: 'totalOrders',
      width: 120
    },
    {
      title: <div className="text-center">Source</div>,
      dataIndex: 'source',
      width: 120,
      className: 'text-center',
      render: (source) => <p>{source?.split('_')?.join(' ')}</p>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 200,
      fixed: 'right',
      render: (status) => (
        <GetStatus
          currStatus={status}
          PROCESSING={
            status === 'BOOKING_IN_PROGRESS' || status === 'READY_TO_BOOK' ? 'PROCESSING' : ''
          }
          SUCCESS="BOOKING_COMPLETED"
          FAILED="BOOKING_FAILED"
          CANCELLED="CANCELLED"
          CUSTOM_LABEL={status?.split('_')?.join(' ')}
          SHOW_TAGS
        />
      )
    }
  ];

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">B2B Orders List</h2>
        <Button className="m-0" onClick={handleGetBulkOrders}>
          Refresh
        </Button>
      </div>
      <Table
        columns={tableColumns}
        loading={isTableLoading}
        rowKey={'id'}
        scroll={{ x: 1700, y: '70vh' }}
        sticky
        dataSource={tableData?.bulkOrders}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: tableData?.count ? Math.ceil(tableData?.count / pageLimit) * pageLimit : 0,
          onChange: onPageChange
        }}
      />
    </>
  );
};

export default B2BBulkOrdersList;
