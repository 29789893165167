import React, { useEffect, useState } from "react";
import FixedChargesTable from "./FixedChargesTableColumns";
import { Button, Select, Table } from "antd";
import { getFinTechUsers } from "../../FintechOrders/api";
import { listAllFixedCharges } from "./api";
import FixedChargeDrawer from "./FixedChargeDrawer";
import { ReloadOutlined } from "@ant-design/icons";

const FixedCharges: React.FC = () => {
  const { tableColumns } = FixedChargesTable();
  const [fixedChargesData, setFixedChargesData] = useState([]);
  const [users, setUsers] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }]);
  const [chargesListLoading, setChargesListLoading] = useState<boolean>(false);
  const [searchUserId, setSearchUserId] = useState<string>('');
  const [visible, setVisible] = useState(false);

  const getUsers = async () => {
    const usersData = await getFinTechUsers({ setLoading: setChargesListLoading });
    const dropDownInterFace: { label: string; value: string }[] = [];
    for (let i = 0; i < usersData.length; i++) {
      dropDownInterFace.push({ label: usersData[i].identifier, value: usersData[i].userId });
    }
    setUsers(dropDownInterFace);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleUser = async (value: any) => {
    setSearchUserId(value);
    const requestPayload = {
      userId: value
    };
    const listData = await listAllFixedCharges({ body: requestPayload, setLoading: setChargesListLoading });
    setFixedChargesData(listData);
  };

  const handleGetOrder = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const refreshData = async () => {
    const listData = await listAllFixedCharges({ body: { userId: searchUserId }, setLoading: setChargesListLoading });
    setFixedChargesData(listData);
  };

  const handleRefresh = async () => {
    if (searchUserId) {
      await refreshData();
    }
  };
  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <div className='flex items-center' style={{ gap: '10px' }}>
          <h2 className="text-xl font-normal m-0"> Charges </h2>
          <Select placeholder="Select user name" options={users} onChange={handleUser} style={{ width: '200px' }} />
        </div>
        <div className="flex items-center">
          <Button size="large"  onClick={handleRefresh} className="mr-8" icon={ <ReloadOutlined  />}>    
           Referesh
          </Button>
          <Button className="m-0" onClick={handleGetOrder}>
            Add
          </Button>
        </div>
      </div>
      <div className="p-7 mx-7 my-5 primary-box-shadow flex flex-col rounded-xl">
        <Table
          columns={tableColumns}
          dataSource={fixedChargesData}
          pagination={false}
          loading={chargesListLoading}
          rowKey={'rowHeading'}
        ></Table>
      </div>
      <FixedChargeDrawer
        visible={visible}
        onClose={onClose}
        userId={searchUserId}
        refreshData={refreshData}
        users={users}
      />
    </div>
  );
};

export default FixedCharges;