import React, { FC } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdDelete, MdEmail } from 'react-icons/md';
import { Button, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { BiEdit } from 'react-icons/bi';
import { IAddressList } from '../../../../../interfaces/b2bAddressBook';

interface IAddressCard {
  address: IAddressList;
  onClickDelete: (address: IAddressList) => void;
  onClickEdit: (address: IAddressList) => void;
}
const AddressCard: FC<IAddressCard> = ({ address, onClickDelete, onClickEdit }) => {
  return (
    <div
      className={`relative ${address?.default && 'bg-yellow-50'} ${
        !address?.default && 'hover:bg-gray-200'
      } p-3 w-full md:w-[49%] xl:w-[32%] border border-gray-400 rounded-xl transition duration-[400ms]`}>
      {address?.default && (
        <Tag
          color="blue"
          className="m-0 text-md w-[100px] h-7 text-center absolute top-0 right-0 flex justify-center items-center">
          Default
        </Tag>
      )}

      <div className="flex flex-col justify-between h-full">
        <div>
          <p className="m-0 p-0 text-xs mb-1">
            Created: {moment(address?.createdAt).format('DD-MM-YYYY, HH:MM:SS')}
          </p>

          <Tag className="w-full text-center text-lg bg-gray-300">{address?.tag}</Tag>

          <h3 className="text-xl capitalize my-2">{address?.name || '--'}</h3>
          {address?.companyName && (
            <p>
              <b>Company Name :- </b>
              {address?.companyName}
            </p>
          )}

          {address?.savedAs && (
            <p>
              <b>Saved As: </b>
              {address?.savedAs}
            </p>
          )}

          <div className="my-2">
            <p className="font-bold mt-2">Address :-</p>

            {address?.line1 && (
              <p>
                <b>Line 1: </b>
                {address?.line1?.trim()}
              </p>
            )}
            {address?.line2 && (
              <p>
                <b>Line 2: </b>
                {address?.line2?.trim()}
              </p>
            )}
            {address?.line3 && (
              <p>
                <b>Line 3: </b>
                {address?.line3}
              </p>
            )}

            {address?.isdCode && (
              <p>
                <b>ISD Code: </b>
                {address?.isdCode}
              </p>
            )}
          </div>

          <div className="mt-2">
            <p>
              {address?.city && <span className="mr-1">{`${address?.city?.trim()},`}</span>}

              {address?.state && (
                <span className="mr-1">
                  {address?.state?.trim()} ({address?.stateCode?.trim()}),
                </span>
              )}

              {address?.country && (
                <span className="mr-1">{`${address?.country?.trim()} (${address?.countryCode?.trim()})`}</span>
              )}

              {address?.postalCode && <span>{`- ${address?.postalCode?.trim()}.`}</span>}
            </p>

            <div>
              {address?.mobileNo && (
                <div className="flex gap-2 items-center">
                  <FaPhoneAlt className="text-blue-500" />
                  <div className="flex">
                    <p>{address?.mobileNo}</p>
                    {address?.alternatePhoneNo && <p>, {address?.alternatePhoneNo}</p>}
                  </div>
                </div>
              )}

              {address?.email && (
                <div className="flex gap-2 items-center">
                  <MdEmail className="text-blue-500" />
                  <p>{address?.email}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2">
          <Tooltip title="Edit">
            <Button
              onClick={() => onClickEdit(address)}
              className="border-none"
              icon={<BiEdit size={25} color="blue" />}
            />
          </Tooltip>

          {!address?.default && (
            <Tooltip title="Delete">
              <Button
                onClick={() => onClickDelete(address)}
                className="border-none"
                icon={<MdDelete size={25} color="red" />}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
