import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Button, Modal, Table, Tag } from 'antd';
import { GetProviderLogo } from '../../../../../utils/GetProviderLogos';
import moment from 'moment';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import ItemTableModal from '../ItemTableModal';
import { IBulkOrder, ICarrier, IItem, IPackage } from '../../../../../interfaces/b2bBulkOrder';

interface ICarrierDetails {
  ordersData: IBulkOrder | undefined;
  showCarrierDetailsModal: boolean;
  setCarrierDetailsModal: Dispatch<SetStateAction<boolean>>;
  setSelectedOrder: Dispatch<SetStateAction<IBulkOrder | undefined>>;
}
const CarrierDetails: FC<ICarrierDetails> = ({
  ordersData,
  setSelectedOrder,
  showCarrierDetailsModal,
  setCarrierDetailsModal
}) => {
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const [isItemsModalOpen, setIsItemsModalOpen] = useState<boolean>(false);

  const carrierDetailsColumns: ColumnsType<ICarrier> = [
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
      width: 100,
      render: (text, item) => (
        <img
          src={item?.logo ? item?.logo : GetProviderLogo(text || '')}
          alt="provider"
          style={window.innerWidth > 699 ? { width: '6rem' } : { width: '3rem' }}
        />
      )
    },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      width: 100,
      render: (text) => <Tag>{text || '--'}</Tag>
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      render: (text: string) => <span>{moment(text).format('YYYY-MM-DD, HH:mm:ss')}</span>
    },
    {
      title: 'Total Packages',
      dataIndex: 'packages',
      key: 'packages',
      width: 200,
      render: (text) => text?.length || '0'
    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      width: 200,
      render: (text) => text || '--'
    }
  ];

  const handleItemsModal = (data: IItem[]) => {
    setSelectedItems(data);
    setIsItemsModalOpen(true);
  };
  const packagesColumns: ColumnsType<IPackage> = [
    {
      title: 'Package Id',
      dataIndex: 'id',
      width: 200,
      fixed: 'left',
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: (
        <div>
          Client Package <br /> Reference Id
        </div>
      ),
      dataIndex: 'clientPackageReferenceId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: <div>Package Tracking Number</div>,
      dataIndex: 'packageTrackingNumber',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Length',
      dataIndex: 'length',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Width',
      dataIndex: 'width',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Height',
      dataIndex: 'height',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Total Items',
      dataIndex: 'totalItems',
      width: 200,
      render: (data) => data || 'NA'
    },
    {
      title: 'Items',
      dataIndex: 'items',
      width: 200,
      render: (data) => <Button onClick={() => handleItemsModal(data)}>View</Button>
    }
  ];

  const onClose = () => {
    setSelectedOrder(undefined);
    setCarrierDetailsModal(false);
  };

  return (
    <Modal
      title={`Carriers Details - ${ordersData?.id}`}
      width="80%"
      open={showCarrierDetailsModal}
      onCancel={onClose}>
      <div className="mt-5">
        <Table
          columns={carrierDetailsColumns}
          dataSource={ordersData?.carriers || []}
          pagination={false}
          rowKey={'savedAs'}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div className="bg-white">
                  <p className="my-2 text-xl">
                    <strong>Packages</strong> {`( ${record?.packages?.length} )`}
                  </p>
                  <Table
                    columns={packagesColumns}
                    dataSource={record?.packages || []}
                    pagination={false}
                  />
                </div>
              );
            },
            rowExpandable: (record) => record.packages && record.packages.length > 0
          }}
        />

        <ItemTableModal
          isItemsModalOpen={isItemsModalOpen}
          setIsItemsModalOpen={setIsItemsModalOpen}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </div>
    </Modal>
  );
};

export default CarrierDetails;
