import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { IECBOEmailVerifiedData } from '../../../../../interfaces/ecboBulkOrder';
import { Button, Form, Input, Modal, Spin } from 'antd';
import Formlabel from '../../../../CommonComponents/FormLabel';
import { generateDockets } from '../../api';
import { ArrayToExcelCsv } from '../../../../../utils/Excel-Conversion/ArrayToExcelCsv';
import { FaDownload } from 'react-icons/fa';

interface IGenerateDockets {
  emailVerifiedData: IECBOEmailVerifiedData | undefined;
  generateDocketModal: boolean;
  setGenerateDocketModal: Dispatch<SetStateAction<boolean>>;
  docketsResponse: string[];
  setDocketsResponse: Dispatch<SetStateAction<string[]>>;
  generateDocket: () => void;
}
const GenerateDockets: FC<IGenerateDockets> = ({
  emailVerifiedData,
  generateDocketModal,
  setGenerateDocketModal,
  docketsResponse,
  setDocketsResponse,
  generateDocket
}) => {
  const [docketsForm] = Form.useForm();
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (emailVerifiedData?.email) {
      docketsForm.setFieldValue('userEmail', emailVerifiedData?.email);
    }
  }, []);

  const onSubmit = async (formValues: { userEmail: string; quantity: string }) => {
    await generateDockets({
      setLoading: setIsModalLoading,
      setData: setDocketsResponse,
      emailId: emailVerifiedData?.email || '',
      quantity: formValues?.quantity
    });
  };

  const onClose = () => {
    setGenerateDocketModal(false);
  };

  return (
    <Modal open={generateDocketModal} onCancel={onClose} title="Generate Dockets" footer={false}>
      <Spin spinning={isModalLoading}>
        <div className="flex flex-col gap-5">
          <Form
            form={docketsForm}
            id="generateDocketsForm"
            layout="vertical"
            scrollToFirstError
            onFinish={onSubmit}>
            <div className="flex flex-col gap-2">
              <Formlabel labelText="Email Id :" required />
              <Form.Item
                name="userEmail"
                rules={[
                  { required: true, message: 'Please enter email id' },
                  {
                    pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                    message: 'Please enter valid email id.'
                  }
                ]}>
                <Input disabled type="email" placeholder="Enter Email" />
              </Form.Item>
            </div>

            <div className="flex flex-col gap-2">
              <Formlabel labelText="Quantity :" required />
              <Form.Item
                name="quantity"
                rules={[{ required: true, message: 'Please enter quantity.' }]}>
                <Input type="number" placeholder="Enter quantity" />
              </Form.Item>
            </div>
          </Form>

          <Button type="primary" htmlType="submit" form="generateDocketsForm">
            Generate
          </Button>
        </div>

        {!!docketsResponse?.length && (
          <div className="flex mt-5">
            <Button className="w-full" icon={<FaDownload />} onClick={generateDocket}>
              Download Dockets
            </Button>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default GenerateDockets;
