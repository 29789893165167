import React, { Dispatch, FC, SetStateAction } from 'react';
import { IAddress, IBulkOrder } from '../../../../../interfaces/b2bBulkOrder';
import { Button, PaginationProps, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import { GetProviderLogo } from '../../../../../utils/GetProviderLogos';
import moment from 'moment';
import UserDetails from '../UserDetails';

interface IOrdersTable {
  ordersData: IBulkOrder[] | undefined;
  setSelectedOrder: Dispatch<SetStateAction<IBulkOrder | undefined>>;
  setShowBasicDetailsModal: Dispatch<SetStateAction<boolean>>;
  setAddressDetailsModal: Dispatch<SetStateAction<boolean>>;
  setCarrierDetailsModal: Dispatch<SetStateAction<boolean>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  pageLimit: number;
  setPageLimit: Dispatch<SetStateAction<number>>;
  pagesCount: number;
  tableLoading: boolean;
}
const OrdersTable: FC<IOrdersTable> = ({
  ordersData,
  setSelectedOrder,
  setShowBasicDetailsModal,
  setAddressDetailsModal,
  setCarrierDetailsModal,
  currentPage,
  setCurrentPage,
  pageLimit,
  setPageLimit,
  pagesCount,
  tableLoading
}) => {
  const tableColumns: ColumnsType<IBulkOrder> = [
    {
      title: 'Order Id',
      dataIndex: 'id',
      width: 200,
      fixed: 'left',
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Tracking Number',
      dataIndex: 'carriers',
      key: 'trackingNumber',
      width: 180,
      render: (text) => <GetCopyTextDiv text={text[0]?.trackingNumber} />
    },
    {
      title: 'Carriers',
      dataIndex: 'carriers',
      key: 'carrier',
      render: (text) => (
        <img
          src={GetProviderLogo(text[0]?.carrier || '')}
          alt="provider"
          style={window.innerWidth > 699 ? { width: '6rem' } : { width: '3rem' }}
        />
      ),
      width: 150
    },
    {
      title: 'Service',
      dataIndex: 'carriers',
      key: 'service',
      width: 100,
      render: (text) => <Tag>{text[0]?.service || '--'}</Tag>
    },
    {
      title: 'Source',
      dataIndex: 'addresses',
      key: 'source',
      width: 250,
      render: (address) => {
        const currentAddress =
          address?.filter((item: IAddress) => item?.savedAs === 'SOURCE') || [];

        return currentAddress?.length ? (
          <p>
            {`${currentAddress[0]?.city}, ${currentAddress[0]?.state} - ${currentAddress[0]?.postalCode}`}{' '}
          </p>
        ) : (
          <p>NA</p>
        );
      }
    },
    {
      title: 'Destination',
      dataIndex: 'addresses',
      key: 'destination',
      width: 250,
      render: (address) => {
        const currentAddress =
          address?.filter((item: IAddress) => item?.savedAs === 'DESTINATION') || [];
        return currentAddress?.length ? (
          <p>
            {`${currentAddress[0]?.city}, ${currentAddress[0]?.state} - ${currentAddress[0]?.postalCode}`}{' '}
          </p>
        ) : (
          <p>NA</p>
        );
      }
    },
    {
      title: 'Return',
      dataIndex: 'addresses',
      key: 'return',
      width: 250,
      render: (address) => {
        const currentAddress =
          address?.filter((item: IAddress) => item?.savedAs === 'RETURN') || [];
        return currentAddress?.length ? (
          <p>
            {`${currentAddress[0]?.city}, ${currentAddress[0]?.state} - ${currentAddress[0]?.postalCode}`}{' '}
          </p>
        ) : (
          <p>NA</p>
        );
      }
    },
    {
      title: 'Client Order Reference Id',
      dataIndex: 'clientOrderReferenceId',
      width: 200,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 120,
      render: (text: string) => <span>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>
    },
    {
      title: 'Insurance Id',
      dataIndex: 'insuranceId',
      width: 120,
      render: (id) => <GetCopyTextDiv text={id} />
    },
    {
      title: 'Tracking Status',
      dataIndex: 'trackingStatus',
      width: 120,
      render: (text) => <Tag>{text}</Tag>
    },
    {
      title: 'Order Type',
      dataIndex: 'orderType',
      width: 140,
      render: (text) => <Tag>{text}</Tag>
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      width: 140,
      render: (text) => <Tag>{text}</Tag>
    },
    {
      title: 'Total Weight',
      dataIndex: 'totalWeight',
      width: 100
    },
    {
      title: 'Total Length',
      dataIndex: 'totalLength',
      width: 100
    },
    {
      title: 'Total Width',
      dataIndex: 'totalWidth',
      width: 100
    },
    {
      title: 'Total Height',
      dataIndex: 'totalHeight',
      width: 100
    },
    {
      title: 'Total Partners',
      dataIndex: 'totalPartners',
      width: 100
    },
    {
      title: 'Total Product Value',
      dataIndex: 'totalProductValue',
      width: 100
    },
    {
      title: 'Source',
      dataIndex: 'source',
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (value) => (value ? <Tag>{value?.split('_')?.join(' ')}</Tag> : '--')
    }
  ];

  const onSetOrderDetails = (order: IBulkOrder) => {
    setSelectedOrder(order);
  };

  const onViewBasicDetails = (order: IBulkOrder) => {
    setShowBasicDetailsModal(true);
    onSetOrderDetails(order);
  };
  const onAddressBasicDetails = (order: IBulkOrder) => {
    setAddressDetailsModal(true);
    onSetOrderDetails(order);
  };
  const onViewCarrierDetails = (order: IBulkOrder) => {
    setCarrierDetailsModal(true);
    onSetOrderDetails(order);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  return (
    <div>
      <Table
        columns={tableColumns}
        dataSource={ordersData}
        loading={tableLoading}
        scroll={{ x: 3520 }}
        rowKey={'id'}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div className="flex flex-col gap-5">
                <UserDetails ordersData={record} />

                <div className="flex gap-5">
                  <Button type="primary" onClick={() => onViewBasicDetails(record)}>
                    Basic Details
                  </Button>
                  <Button type="primary" onClick={() => onAddressBasicDetails(record)}>
                    Address Details
                  </Button>
                  <Button type="primary" onClick={() => onViewCarrierDetails(record)}>
                    Carrier Details
                  </Button>
                </div>
              </div>
            );
          }
        }}
        pagination={{
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          current: currentPage,
          pageSize: pageLimit,
          pageSizeOptions: [20, 30, 50],
          total: pagesCount ? Math.ceil(pagesCount / pageLimit) * pageLimit : 0,
          onChange: onPageChange
        }}
      />
    </div>
  );
};

export default OrdersTable;
