import { Select } from 'antd';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { PRIORITY_OPTIONS, RATE_CATEGORY } from '../constants';
import Formlabel from '../../../../CommonComponents/FormLabel';

interface IAdditionalDetails {
  disableStep: boolean;
  priority: string;
  setPriority: Dispatch<SetStateAction<string>>;
  rateCategory: string;
  setRateCategory: Dispatch<SetStateAction<string>>;
  isUploadSuccess: boolean;
}

const AdditionalDetails: FC<IAdditionalDetails> = ({
  disableStep,
  priority,
  setPriority,
  rateCategory,
  setRateCategory,
  isUploadSuccess
}) => {
  return (
    <div className={`${disableStep ? 'opacity-50  cursor-not-allowed select-none' : ''} px-3 pt-4`}>
      <div className="flex flex-col gap-2 w-full">
        <Formlabel labelText="Priority :" required />
        <Select
          className="w-full"
          value={priority}
          onChange={(e) => setPriority(e)}
          options={PRIORITY_OPTIONS}
          disabled={disableStep || isUploadSuccess}
        />
      </div>

      <div className="flex flex-col gap-2 w-full my-5">
        <Formlabel labelText="Rate Category :" required />
        <Select
          className="w-full"
          value={rateCategory}
          onChange={(e) => setRateCategory(e)}
          options={RATE_CATEGORY}
          disabled={disableStep || isUploadSuccess}
        />
      </div>
    </div>
  );
};

export default AdditionalDetails;
