import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IAddress, IBulkOrder } from '../../../../../interfaces/b2bBulkOrder';

interface IAddressDetails {
  ordersData: IBulkOrder | undefined;
  setAddressDetailsModal: Dispatch<SetStateAction<boolean>>;
  showAddressDetailsModal: boolean;
  setSelectedOrder: Dispatch<SetStateAction<IBulkOrder | undefined>>;
}

const AddressDetails: FC<IAddressDetails> = ({
  ordersData,
  showAddressDetailsModal,
  setAddressDetailsModal,
  setSelectedOrder
}) => {
  const [addressData, setAddressData] = useState<IAddress[]>([]);
  useEffect(() => {
    if (ordersData && showAddressDetailsModal) {
      setAddressData(ordersData.addresses);
    }
  }, [ordersData]);

  const addressDetailsColumns: ColumnsType<IAddress> = [
    {
      title: ' ',
      dataIndex: 'savedAs',
      key: 'savedAs',
      fixed: 'left',
      width: 150,
      render: (text) => (text ? <p className="m-0 font-semibold">{text}</p> : '-')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Address line 1',
      dataIndex: 'line1',
      key: 'line1',
      width: 200,
      render: (text) => text || 'NA'
    },
    {
      title: 'Address line 2',
      dataIndex: 'line2',
      key: 'line2',
      width: 200,
      render: (text) => text || 'NA'
    },
    {
      title: 'Address line 3',
      dataIndex: 'line3',
      key: 'line3',
      width: 200,
      render: (text) => text || 'NA'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'State Code',
      dataIndex: 'stateCode',
      key: 'stateCode',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 120,
      render: (text) => text || 'NA'
    },
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
      key: 'countryCode',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Postal Code',
      dataIndex: 'postalCode',
      key: 'postalCode',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Geo Location',
      dataIndex: 'geoLocation',
      key: 'geoLocation',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Mobile No',
      dataIndex: 'mobileNo',
      key: 'mobileNo',
      width: 150,
      render: (text) => text || 'NA'
    },
    {
      title: 'Alternate Phone No',
      dataIndex: 'alternatePhoneNo',
      key: 'alternatePhoneNo',
      width: 150,
      render: (text) => text || 'NA'
    },

    {
      title: 'ISD Code',
      dataIndex: 'isdCode',
      key: 'isdCode',
      width: 150,
      render: (text) => text || 'NA'
    }
  ];

  const onClose = () => {
    setSelectedOrder(undefined);
    setAddressDetailsModal(false);
  };

  return (
    <Modal
      footer={false}
      title={`Address Details - ${ordersData?.id}`}
      width="80%"
      open={showAddressDetailsModal}
      onCancel={onClose}>
      <Table
        className="mt-5"
        columns={addressDetailsColumns}
        dataSource={addressData}
        pagination={false}
        scroll={{
          x: 2500
        }}
        rowKey={'savedAs'}
      />
    </Modal>
  );
};

export default AddressDetails;
