import { Button, Input, InputRef, Modal, Space, Table, TableColumnType, Tooltip } from 'antd';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ICountry, IStateList } from '../../../../../interfaces/b2bPlacesDetails';
import { getStatesList } from '../api';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/es/table/interface';

interface IStatesModal {
  isOpenStatesModal: boolean;
  setIsOpenStatesModal: Dispatch<SetStateAction<boolean>>;
  setSelectedCountry: Dispatch<SetStateAction<ICountry | undefined>>;
  selectedCountry: ICountry | undefined;
}
const StatesModal: FC<IStatesModal> = ({
  isOpenStatesModal,
  selectedCountry,
  setIsOpenStatesModal,
  setSelectedCountry
}) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [statesData, setStatesData] = useState<IStateList[]>([]);
  const modalWidth = window.innerWidth <= 1024 ? '90%' : '800px';
  const searchStateRef = useRef<InputRef>(null);

  const handleStatesList = async () => {
    await getStatesList({
      setLoading: setModalLoading,
      countyCode: selectedCountry?.codes?.iso2 || '',
      setData: setStatesData
    });
  };

  useEffect(() => {
    if (selectedCountry) {
      handleStatesList();
    }
  }, [selectedCountry]);

  const onModalClose = () => {
    setIsOpenStatesModal(false);
    setSelectedCountry(undefined);
    setStatesData([]);
  };

  const handleSearch = (confirm: FilterDropdownProps['confirm']) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: keyof IStateList): TableColumnType<IStateList> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchStateRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue
        ? recordValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchStateRef?.current?.select(), 100);
      }
    }
  });

  const tableColumns: ColumnsType<IStateList> = [
    {
      title: `Name ${statesData?.length ? `( ${statesData?.length} )` : ''}`,
      key: 'name',
      width: 200,
      dataIndex: 'name',
      render: (name) => name?.length && <GetCopyTextDiv text={name[0]} />,
      ...getColumnSearchProps('name')
    },
    {
      title: 'State Code',
      key: 'code',
      width: 100,
      dataIndex: 'code',
      render: (code) => <GetCopyTextDiv text={code} />
    },
    {
      title: 'Status',
      width: 75,
      dataIndex: 'status',
      render: (status) => (
        <Tooltip title={status}>
          {status === 'ENABLED' ? (
            <MdCheckCircle size={25} color="green" />
          ) : (
            <MdCancel size={25} color="red" />
          )}
        </Tooltip>
      )
    }
  ];

  return (
    <Modal
      title={`States Of ${selectedCountry?.name} ${selectedCountry?.flag?.emoji}`}
      open={isOpenStatesModal}
      onCancel={onModalClose}
      footer={false}
      width={modalWidth}>
      <Table
        loading={modalLoading}
        columns={tableColumns}
        dataSource={statesData}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [20, 30, 50]
        }}
        scroll={{ y: '60vh' }}
      />
    </Modal>
  );
};

export default StatesModal;
