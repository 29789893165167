import React, { Fragment, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { anonymous } from '../config/userRoutes';
import AuthRouter from './AuthRouter';
import jwtDecode from 'jwt-decode';
import { clearCache } from '../../utils/clearCache';

interface UserRouterProps {
  isAuthenticated: boolean;
}

interface IDecodedToken {
  exp: number;
}

const UserRouter: React.FC<UserRouterProps> = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = localStorage.getItem('token');

  const fallback = () => {
    clearCache(true);
    navigate('/signin');
  };
  const validateToken = async () => {
    if (token) {
      try {
        const decodedToken: IDecodedToken = await jwtDecode(token);
        const currentDate = new Date();
        if (decodedToken.exp * 1000 > currentDate.getTime()) {
          if (pathname === '/signin' || pathname === '/') {
            navigate('/dashboard');
          }
          return;
        } else {
          fallback();
        }
      } catch (error) {
        console.error('Invalid token:', error);
        fallback();
        return;
      }
    } else {
      fallback();
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <Fragment>
      <Routes>
        {anonymous.map(({ routePath, Component }) => {
          return <Route key={routePath} path={routePath} element={<Component />}></Route>;
        })}
      </Routes>
      <AuthRouter isAuthenticated={isAuthenticated} />
    </Fragment>
  );
};

export default UserRouter;
