import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import { FaDownload } from 'react-icons/fa';
import { ArrayToExcelCsv } from '../../../../utils/Excel-Conversion/ArrayToExcelCsv';

interface IDownloadDocketsButton {
  title?: string;
  docketsData: string[];
  onlyIcon?: boolean;
}

const DownloadDocketsButton: FC<IDownloadDocketsButton> = ({
  title = 'Dockets',
  docketsData,
  onlyIcon = false
}) => {
  const onClickDownload = () => {
    ArrayToExcelCsv({ data: docketsData, filename: title });
  };

  return (
    <Tooltip title="Download Dockets">
      {onlyIcon ? (
        <Button icon={<FaDownload />} onClick={onClickDownload} />
      ) : (
        <Button
          type="primary"
          className="flex items-center mt-5"
          icon={<FaDownload />}
          onClick={onClickDownload}>
          Download Dockets
        </Button>
      )}
    </Tooltip>
  );
};

export default DownloadDocketsButton;
