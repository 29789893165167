import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { getCountriesList } from '../api';
import { Button, Input, InputRef, Space, Table, TableColumnType, Tooltip } from 'antd';
import { ICountry } from '../../../../../interfaces/b2bPlacesDetails';
import { ColumnsType } from 'antd/es/table';
import GetCopyTextDiv from '../../../../CommonComponents/GetCopyTextDiv';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { FaExternalLinkAlt } from 'react-icons/fa';
import StatesModal from '../StatesModal';

interface ICountries {
  countriesList: ICountry[];
  setCountriesList: Dispatch<SetStateAction<ICountry[]>>;
  countryListLoading: boolean;
  setCountryListLoading: Dispatch<SetStateAction<boolean>>;
}

const Countries: FC<ICountries> = ({
  countriesList,
  setCountriesList,
  countryListLoading,
  setCountryListLoading
}) => {
  const [isOpenStatesModal, setIsOpenStatesModal] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();

  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    getCountriesList({ setLoading: setCountryListLoading, setData: setCountriesList });
  }, []);

  const handleSearch = (confirm: FilterDropdownProps['confirm']) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: keyof ICountry): TableColumnType<ICountry> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];
      return recordValue
        ? recordValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const tableColumns: ColumnsType<ICountry> = [
    {
      title: 'Name',
      key: 'Name',
      dataIndex: 'name',
      width: 200,
      render: (name) => (name?.length ? name[0] : 'NA'),
      ...getColumnSearchProps('name')
    },
    {
      title: 'Flag',
      key: 'flagImage',
      dataIndex: ['flag', 'emoji'],
      width: 100,
      render: (emoji) => (emoji ? <p className="m-0 text-3xl">{emoji}</p> : 'NA')
    },
    {
      title: 'Unicode',
      key: 'unicode',
      dataIndex: ['flag', 'unicode'],
      width: 150,
      render: (unicode) => <GetCopyTextDiv text={unicode} />
    },
    {
      title: 'Codes',
      key: 'codes',
      dataIndex: 'flag',
      width: 270,
      children: [
        {
          title: 'ISD',
          key: 'isd',
          dataIndex: ['codes', 'isd'],
          width: 90,
          render: (isd) => isd || 'NA'
        },
        {
          title: 'ISO2',
          key: 'iso2',
          dataIndex: ['codes', 'iso2'],
          width: 90,
          render: (iso2) => iso2 || 'NA'
        },
        {
          title: 'ISO3',
          key: 'iso3',
          dataIndex: ['codes', 'iso3'],
          width: 90,
          render: (iso3) => iso3 || 'NA'
        }
      ]
    },
    {
      title: 'Status',
      width: 75,
      dataIndex: 'status',
      fixed: 'right',
      render: (status) => (
        <Tooltip title={status}>
          {status === 'ENABLED' ? (
            <MdCheckCircle size={25} color="green" />
          ) : (
            <MdCancel size={25} color="red" />
          )}
        </Tooltip>
      )
    },
    {
      title: 'States',
      key: 'states',
      width: 85,
      fixed: 'right',
      dataIndex: ['codes', 'iso2'],
      render: (_, country) => (
        <Button
          onClick={() => onStatesClick(country)}
          className="text-blue-500"
          icon={<FaExternalLinkAlt />}
        />
      )
    }
  ];

  const onStatesClick = (country: ICountry) => {
    setIsOpenStatesModal(true);
    setSelectedCountry(country);
  };

  return (
    <>
      <Table
        columns={tableColumns}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [20, 30, 50]
        }}
        scroll={{ x: 845, y: '55vh' }}
        dataSource={countriesList}
        loading={countryListLoading}
      />

      <StatesModal
        isOpenStatesModal={isOpenStatesModal}
        setIsOpenStatesModal={setIsOpenStatesModal}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
      />
    </>
  );
};

export default Countries;
