import { Dispatch, SetStateAction } from 'react';
import { httpClient } from '../../../utils/apiRequest';
import { ErrorMessage } from '../../../utils/ResponseMessages';
import { IFranchisee } from '../../../interfaces/ecboBulkOrder';

export const getEcboBatches = async ({
  setLoading,
  email,
  setData,
  callBackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  email: string;
  setData: Dispatch<SetStateAction<IFranchisee[] | null>>;
  callBackFunc?: (email: string) => void;
}): Promise<any> => {
  setLoading(true);
  try {
    const response = await httpClient?.get(`/ecbo/batches/${email}?skip=0&limit=12`);
    setData(response?.data?.data);
    if (callBackFunc) callBackFunc(email);
  } catch (error: any) {
    setData(null);
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const generateReceipt = async ({
  setLoading,
  payload,
  callBackFunc
}: {
  setLoading: Dispatch<SetStateAction<boolean>>;
  payload: { id: string; userId: string; customerCopy: boolean };
  callBackFunc?: () => void;
}): Promise<any> => {
  setLoading(true);
  try {
    const response = await httpClient?.post(`/ecbo/receipt`, payload);
    window.open(response?.data?.data?.receiptUrl, '_blank');
    if (callBackFunc) callBackFunc();
  } catch (error: any) {
    ErrorMessage(error?.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
