import { Tag } from 'antd';
import React from 'react';

interface GetStatusProps {
  currStatus?: string;
  PROCESSING?: string;
  SUCCESS?: string;
  COMPLETED?: string;
  FAILED?: string;
  CANCELLED?: string;
  UNKNOWN?: string;
  CUSTOM_LABEL?: string;
  SHOW_TAGS?: boolean;
}

const GetStatus: React.FC<GetStatusProps> = ({
  currStatus,
  // mention actual key values
  PROCESSING,
  SUCCESS,
  COMPLETED,
  FAILED,
  CANCELLED,
  UNKNOWN = 'Unknown',
  CUSTOM_LABEL,
  SHOW_TAGS = false
}) => {
  switch (currStatus) {
    case PROCESSING:
      return SHOW_TAGS ? (
        <Tag className="tracking-wider" color="warning">
          {CUSTOM_LABEL ? CUSTOM_LABEL : 'PROCESSING'}
        </Tag>
      ) : (
        <p className="text-yellow-400 tracking-widest">
          {CUSTOM_LABEL ? CUSTOM_LABEL : 'PROCESSING'}
        </p>
      );
    case SUCCESS:
      return SHOW_TAGS ? (
        <Tag color="success" className="tracking-wider">
          {CUSTOM_LABEL ? CUSTOM_LABEL : 'SUCCESS'}
        </Tag>
      ) : (
        <p className="text-green-400 tracking-widest">{CUSTOM_LABEL ? CUSTOM_LABEL : 'SUCCESS'}</p>
      );
    case FAILED:
      return SHOW_TAGS ? (
        <Tag className="tracking-wider" color="error">
          {CUSTOM_LABEL ? CUSTOM_LABEL : 'FAILED'}
        </Tag>
      ) : (
        <p className="text-red-400 tracking-widest">{CUSTOM_LABEL ? CUSTOM_LABEL : 'FAILED'}</p>
      );
    case CANCELLED:
      return SHOW_TAGS ? (
        <Tag className="tracking-wider" color="default">
          {CUSTOM_LABEL ? CUSTOM_LABEL : 'CANCELLED'}
        </Tag>
      ) : (
        <p className="text-gray-400 tracking-widest">{CUSTOM_LABEL ? CUSTOM_LABEL : 'CANCELLED'}</p>
      );
    case COMPLETED:
      return SHOW_TAGS ? (
        <Tag className="tracking-wider" color="default">
          {CUSTOM_LABEL ? CUSTOM_LABEL : 'COMPLETED'}
        </Tag>
      ) : (
        <p className="text-gray-400 tracking-widest">{CUSTOM_LABEL ? CUSTOM_LABEL : 'COMPLETED'}</p>
      );
    default:
      return SHOW_TAGS ? (
        <Tag className="tracking-wider" color="default">
          {CUSTOM_LABEL ? CUSTOM_LABEL : UNKNOWN}
        </Tag>
      ) : (
        <p className="text-gray-400 tracking-widest">{CUSTOM_LABEL ? CUSTOM_LABEL : UNKNOWN}</p>
      );
  }
};

export default GetStatus;
