import { Button } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import Formlabel from '../../../../CommonComponents/FormLabel';
import SelectPickupAddressModal from '../SelectPickupAddressModal';
import { IPickupAddressItem } from '../../../../../interfaces/b2bBulkOrder';
import AddressCard from '../AddressCard';

interface IPickupAddress {
  businessId: string;
  selectedAddress: IPickupAddressItem | null;
  setSelectedAddress: Dispatch<SetStateAction<IPickupAddressItem | null>>;
  nextStep: () => void;
  disableStep: boolean;
}
const PickupAddress: FC<IPickupAddress> = ({
  businessId,
  selectedAddress,
  setSelectedAddress,
  disableStep,
  nextStep
}) => {
  const [pickupAddressModal, setPickupAddressModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setPickupAddressModal(true);
  };
  return (
    <>
      <div
        className={`${
          disableStep ? 'opacity-50 bg-gray-200 cursor-not-allowed select-none' : ''
        } px-3 py-4`}>
        {businessId ? (
          <>
            {selectedAddress ? (
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3 mb-3">
                  <Formlabel labelText="Selected Pickup Address :" />

                  <Button
                    disabled={disableStep}
                    onClick={handleModalOpen}
                    className="primary-button">
                    Change
                  </Button>
                </div>

                <AddressCard
                  key={selectedAddress?.id}
                  address={selectedAddress}
                  showButton={false}
                  isSelected={selectedAddress?.id === selectedAddress?.id}
                  length={1}
                />
              </div>
            ) : (
              <div className="flex items-center gap-3 mb-3">
                <Formlabel labelText="Select Pickup Address :" required />
                <Button onClick={handleModalOpen} className="primary-button">
                  Select
                </Button>
              </div>
            )}
            <div className="mt-5 flex justify-center">
              <Button
                disabled={disableStep || !selectedAddress}
                type={disableStep || !selectedAddress ? 'default' : 'primary'}
                onClick={nextStep}>
                Proceed
              </Button>
            </div>
          </>
        ) : (
          <p className="h-full">Please select any business.</p>
        )}
      </div>

      <SelectPickupAddressModal
        businessId={businessId}
        pickupAddressModal={pickupAddressModal}
        setPickupAddressModal={setPickupAddressModal}
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
      />
    </>
  );
};

export default PickupAddress;
