import { Button, Empty, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getB2BBulkOrderDetails, getBookingReport, getShippingLabel } from './api';
import { IB2BBulkOrderDetails, IBulkOrder } from '../../../../interfaces/b2bBulkOrder';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import moment from 'moment';
import GetStatus from '../../../../utils/GetStatus';
import OrdersTable from './OrdersTable';
import AddressDetails from './AddressDetails';
import CarrierDetails from './CarrierDetails';
import BasicDetails from './BasicDetails';
import { FaDownload } from 'react-icons/fa';

const B2BBulkOrderDetails = () => {
  const { bulkId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<IB2BBulkOrderDetails>();

  const [showBasicDetailsModal, setShowBasicDetailsModal] = useState<boolean>(false);
  const [showAddressDetailsModal, setAddressDetailsModal] = useState<boolean>(false);
  const [showCarrierDetailsModal, setCarrierDetailsModal] = useState<boolean>(false);
  const [downloadLabelLoading, setDownloadLabelLoading] = useState<boolean>(false);
  const [downloadReportLoading, setDownloadReportLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);

  const [selectedOrder, setSelectedOrder] = useState<IBulkOrder | undefined>(undefined);
  const idNotFound = () => {
    navigate('/b2b-bulk-orders-list');
  };

  const handleGetBulkOrderDetails = async () => {
    if (bulkId) {
      await getB2BBulkOrderDetails({
        bulkId,
        setLoading: detailsData ? setTableLoading : setLoading,
        setData: setDetailsData,
        pageLimit: pageLimit,
        currentPage: currentPage
      });
    }
  };
  useEffect(() => {
    if (bulkId) {
      handleGetBulkOrderDetails();
    } else idNotFound();
  }, [bulkId, pageLimit, currentPage]);

  const handleDownload = async (
    url: string | null | undefined,
    downloadType: 'LABEL' | 'REPORT'
  ) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      if (downloadType === 'LABEL') {
        await getShippingLabel({
          setLoading: setDownloadLabelLoading,
          bulkId: bulkId || ''
        });
      } else if (downloadType === 'REPORT') {
        await getBookingReport({
          setLoading: setDownloadReportLoading,
          bulkId: bulkId || ''
        });
      }
    }
  };
  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-normal m-0">B2B Bulk Order Details</h2>

        <Button
          disabled={loading || tableLoading}
          className="m-0"
          onClick={handleGetBulkOrderDetails}>
          Refresh
        </Button>
      </div>

      {detailsData?.status === 'BOOKING_COMPLETED' ? (
        <div className="flex gap-5 justify-end">
          <Button
            icon={<FaDownload />}
            loading={downloadReportLoading}
            className="primary-button flex items-center"
            onClick={() => handleDownload(detailsData?.bookingReportUrl, 'REPORT')}>
            Report
          </Button>
          <Button
            loading={downloadLabelLoading}
            icon={<FaDownload />}
            className="primary-button flex items-center"
            onClick={() => handleDownload(detailsData?.shippingLabelUrl, 'LABEL')}>
            Shipping Label
          </Button>
        </div>
      ) : null}

      {loading ? (
        <div className="flex flex-col gap-10 mt-5">
          <div className="flex gap-10">
            <Skeleton active={loading} />
            <Skeleton active={loading} />
          </div>
          <Skeleton active={loading} />
        </div>
      ) : detailsData ? (
        <>
          <div className="flex flex-col gap-8 xl:flex-row mt-5">
            <div className="w-full">
              <h2 className="text-xl m-0 pb-2 font-bold border-b border-b-gray-300">
                1. Basic Details :
              </h2>

              <div className="flex flex-col gap-2 mt-2">
                <div className="flex text-lg">
                  <p className="w-[200px] font-semibold flex gap-2 justify-between mr-4">
                    Bulk Id <span>:</span>
                  </p>
                  <GetCopyTextDiv text={detailsData?.id} />
                </div>

                <div className="flex text-lg">
                  <p className="w-[200px] font-semibold flex gap-2 justify-between mr-4">
                    Business Id <span>:</span>
                  </p>

                  <GetCopyTextDiv text={detailsData?.businessId} />
                </div>

                <div className="flex text-lg">
                  <p className="w-[200px] font-semibold flex gap-2 justify-between mr-4">
                    User Id <span>:</span>
                  </p>
                  <GetCopyTextDiv text={detailsData?.userId} />
                </div>

                <div className="flex text-lg">
                  <p className="w-[200px] font-semibold flex gap-2 justify-between mr-4">
                    Date & Time <span>:</span>
                  </p>

                  <span className="font-bold text-blue-500 tracking-widest">
                    {moment(detailsData?.createdAt).format('DD-MM-YYYY, HH:mm:ss')}
                  </span>
                </div>

                <div className="flex text-lg">
                  <p className="w-[200px] font-semibold flex gap-2 justify-between mr-4">
                    Source <span>:</span>
                  </p>
                  <GetCopyTextDiv text={detailsData?.source?.split('_')?.join(' ')} />
                </div>

                <div className="flex text-lg items-center">
                  <p className="w-[200px] font-semibold flex gap-2 justify-between mr-4">
                    Status <span>:</span>
                  </p>
                  <p className="text-lg font-bold">
                    <GetStatus
                      currStatus={detailsData?.status}
                      PROCESSING={
                        detailsData?.status === 'BOOKING_IN_PROGRESS' ||
                        detailsData?.status === 'READY_TO_BOOK'
                          ? 'PROCESSING'
                          : ''
                      }
                      SUCCESS="BOOKING_COMPLETED"
                      FAILED="BOOKING_FAILED"
                      CANCELLED="CANCELLED"
                      CUSTOM_LABEL={detailsData?.status?.split('_')?.join(' ')}
                      SHOW_TAGS
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 justify-between w-full">
              <div className="flex flex-col gap-2">
                <h2 className="text-xl m-0 pb-2 font-bold border-b border-b-gray-300">
                  2. Order Details :
                </h2>
                <div className="flex text-lg">
                  <p className="w-[205px] font-semibold flex gap-2 justify-between mr-4">
                    Serviceable Orders <span>:</span>
                  </p>
                  <p>{detailsData?.serviceableOrders}</p>
                </div>

                <div className="flex text-lg text-red-500">
                  <p className="w-[205px] font-semibold flex gap-2 justify-between mr-4">
                    Unserviceable Orders <span>:</span>
                  </p>
                  <p>{detailsData?.unserviceableOrders}</p>
                </div>

                <div className="flex text-lg text-green-500">
                  <p className="w-[205px] font-semibold flex gap-2 justify-between mr-4">
                    Successful Orders <span>:</span>
                  </p>
                  <p>{detailsData?.successfulOrders}</p>
                </div>

                <div className="flex text-lg text-red-500">
                  <p className="w-[205px] font-semibold flex gap-2 justify-between mr-4">
                    Failed Orders <span>:</span>
                  </p>
                  <p>{detailsData?.failedOrders}</p>
                </div>
              </div>

              <div className="flex text-lg border-y border-y-gray-300 py-2">
                <p className="w-[205px] font-semibold flex gap-2 justify-between mr-4">
                  Total Orders <span>:</span>
                </p>
                <p className="pr-2">{detailsData?.totalOrders}</p>
              </div>
            </div>
          </div>
          <div className="mt-8 mb-5">
            <h2 className="text-xl m-0 pb-2 font-bold border-b border-b-gray-300">
              3. Orders :{' '}
              <span className="font-normal">{`( ${detailsData?.orders?.length} )`}</span>
            </h2>
          </div>

          <OrdersTable
            ordersData={detailsData?.orders}
            setSelectedOrder={setSelectedOrder}
            setShowBasicDetailsModal={setShowBasicDetailsModal}
            setAddressDetailsModal={setAddressDetailsModal}
            setCarrierDetailsModal={setCarrierDetailsModal}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            pagesCount={detailsData?.count}
            tableLoading={tableLoading}
          />
        </>
      ) : (
        <div className="text-red-500 border border-gray-300 h-[350px] flex justify-center items-center">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <p className="text-red-500 text-lg">Something went wrong, Please try again later.</p>
            }
          />
        </div>
      )}

      <BasicDetails
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        showBasicDetailsModal={showBasicDetailsModal}
        setShowBasicDetailsModal={setShowBasicDetailsModal}
      />

      <AddressDetails
        ordersData={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        setAddressDetailsModal={setAddressDetailsModal}
        showAddressDetailsModal={showAddressDetailsModal}
      />

      <CarrierDetails
        ordersData={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        showCarrierDetailsModal={showCarrierDetailsModal}
        setCarrierDetailsModal={setCarrierDetailsModal}
      />
    </>
  );
};

export default B2BBulkOrderDetails;
