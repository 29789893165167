import React, { useEffect, useState } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { Button, Divider, Empty, Form, Input, Pagination, PaginationProps, Spin } from 'antd';

import { getAddressList } from './api';
import { IAddressBookResponse, IAddressList } from '../../../../interfaces/b2bAddressBook';
import Formlabel from '../../../CommonComponents/FormLabel';
import GetCopyTextDiv from '../../../CommonComponents/GetCopyTextDiv';
import AddressCard from './AddressCard';
import AddNewAddress from './AddNewAddress';
import DeleteAddress from './DeleteAddress';
import EditAddressModal from './EditAddressModal';

const B2BAddressBook = () => {
  const initialAddressData: IAddressBookResponse = {
    addresses: [],
    count: 0
  };

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [addressData, setAddressData] = useState<IAddressBookResponse>(initialAddressData);
  const [businessId, setBusinessId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [enteredPincode, setEnteredPincode] = useState<string>('');

  const [addAddressModal, setAddAddressModal] = useState<boolean>(false);

  const [deleteAddressModal, setDeleteAddressModal] = useState<boolean>(false);
  const [deleteAddress, setDeleteAddress] = useState<IAddressList | undefined>(undefined);

  const [editAddressModal, setEditAddressModal] = useState<boolean>(false);
  const [editAddress, setEditAddress] = useState<IAddressList | undefined>(undefined);

  const handleGetAllAddress = async () => {
    await getAddressList({
      setLoading: setTableLoading,
      setData: setAddressData,
      businessId: businessId,
      postalCode: enteredPincode,
      pageLimit: pageLimit,
      currentPage: currentPage
    });
  };

  useEffect(() => {
    if (businessId) {
      handleGetAllAddress();
    }
  }, [businessId, pageLimit, currentPage, enteredPincode]);

  const handleOnSubmit = ({ businessId }: { businessId: string }) => {
    setBusinessId(businessId);
  };

  const onPageChange: PaginationProps['onChange'] = (pageNo, limit) => {
    setCurrentPage(pageNo);
    setPageLimit(limit);
  };

  const onSearchPincode = ({ searchPincode }: { searchPincode: string }) => {
    setEnteredPincode(searchPincode);
  };

  const onClickDelete = (address: IAddressList) => {
    setDeleteAddressModal(true);
    setDeleteAddress(address);
  };

  const onClickEdit = (address: IAddressList) => {
    setEditAddressModal(true);
    setEditAddress(address);
  };
  return (
    <>
      <h2 className="text-xl font-normal m-0">B2B Address Book</h2>

      <Spin spinning={businessId ? false : tableLoading}>
        <div className="flex justify-center my-5">
          <Form
            id="businessIdForm"
            scrollToFirstError
            layout="vertical"
            onFinish={handleOnSubmit}
            className="max-w-[600px] primary-box-shadow p-3 rounded-xl">
            <div className="flex flex-wrap gap-2">
              <Formlabel labelText="Enter Business Id :" required />
              <div className="flex">
                <Form.Item
                  name="businessId"
                  className="m-0"
                  rules={[{ required: true, message: 'Please enter business Id.' }]}>
                  <Input className="rounded-tr-none rounded-br-none" placeholder="Business Id" />
                </Form.Item>
                <Button
                  className="primary-button rounded-tl-none rounded-bl-none"
                  form="businessIdForm"
                  htmlType="submit">
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Spin>

      {businessId && (
        <>
          <Divider />
          <div className="flex items-center mb-5 justify-between">
            <div className="flex gap-3 items-center ">
              <h2 className="text-xl font-normal">Business Id :</h2>
              <GetCopyTextDiv textClassName="text-xl" text={businessId} />
            </div>

            <Button
              className="flex items-center primary-button"
              onClick={() => setAddAddressModal(true)}
              icon={<BiPlusCircle size={18} />}>
              Add Address
            </Button>
          </div>
        </>
      )}

      {businessId && (
        <>
          <div className="flex flex-wrap justify-between">
            <div className="flex flex-wrap gap-2">
              <div className="mt-1">
                <Formlabel labelText={'Search Pincode :'} />
              </div>
              <Form onFinish={onSearchPincode}>
                <Form.Item
                  name="searchPincode"
                  className="m-0"
                  rules={[{ pattern: /^\d{6}$/, message: 'Please enter valid pincode.' }]}>
                  <Input.Search enterButton placeholder="Enter Pincode" className="m-0" />
                </Form.Item>
              </Form>
            </div>

            <AddressPagination
              currentPage={currentPage}
              pageLimit={pageLimit}
              addressData={addressData}
              onPageChange={onPageChange}
            />
          </div>

          <Spin spinning={tableLoading}>
            <div className="flex flex-wrap gap-3">
              {addressData?.addresses?.length ? (
                addressData?.addresses?.map((address) => (
                  <AddressCard
                    key={address?.id}
                    address={address}
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                  />
                ))
              ) : (
                <div className="my-5 py-10 flex w-full justify-center border border-gray-300">
                  <Empty
                    description={`No Address for ${enteredPincode ? enteredPincode : businessId}`}
                  />
                </div>
              )}
            </div>
          </Spin>

          {addressData?.count >= pageLimit && addressData?.addresses?.length >= pageLimit && (
            <AddressPagination
              currentPage={currentPage}
              pageLimit={pageLimit}
              addressData={addressData}
              onPageChange={onPageChange}
            />
          )}
        </>
      )}

      <EditAddressModal
        editAddress={editAddress}
        editAddressModal={editAddressModal}
        setEditAddressModal={setEditAddressModal}
        handleGetAllAddress={handleGetAllAddress}
      />

      <AddNewAddress
        businessId={businessId}
        addAddressModal={addAddressModal}
        setAddAddressModal={setAddAddressModal}
        handleGetAllAddress={handleGetAllAddress}
      />

      <DeleteAddress
        deleteAddress={deleteAddress}
        setDeleteAddress={setDeleteAddress}
        deleteAddressModal={deleteAddressModal}
        setDeleteAddressModal={setDeleteAddressModal}
        handleGetAllAddress={handleGetAllAddress}
      />
    </>
  );
};

export default B2BAddressBook;

export const AddressPagination = ({
  currentPage,
  pageLimit,
  addressData,
  onPageChange
}: {
  currentPage: number;
  pageLimit: number;
  addressData: IAddressBookResponse;
  onPageChange: PaginationProps['onChange'];
}) => {
  return (
    <div className="text-right my-3 pr-4">
      <Pagination
        responsive={true}
        showQuickJumper={true}
        showSizeChanger={true}
        current={currentPage}
        pageSize={pageLimit}
        pageSizeOptions={[20, 30, 50]}
        total={addressData?.count ? Math.ceil(addressData?.count / pageLimit) * pageLimit : 0}
        onChange={onPageChange}
      />
    </div>
  );
};
